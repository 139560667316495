import * as t from 'io-ts'
import { DateFromISOString } from 'io-ts-types'
import { nullableCodec } from 'lib/request'

const TSubscriptionType = t.union([
  t.literal('PUBLIC'),
  t.literal('PRIVATE'),
  t.literal('UNLIMITED'),
])

export type SubscriptionType = t.TypeOf<typeof TSubscriptionType>

const TPeriodTypes = t.literal('MONTH')

export type PeriodTypes = t.TypeOf<typeof TPeriodTypes>

const TTrialPeriodTypes = t.union([
  t.literal('DAY'),
  t.literal('MONTH'),
  t.literal('YEAR'),
])

export type TrialPeriodTypes = t.TypeOf<typeof TTrialPeriodTypes>

export const TPaymentCurrency = t.literal('EUR')

export type PaymentCurrency = t.TypeOf<typeof TPaymentCurrency>

export const TSubscriptionFeatures = t.array(
  t.strict({
    code: t.string,
    name: t.string,
  }),
)

export const TSubscriptionAbstract = t.strict({
  thesis: t.string,
  order: t.number,
})

export const TSubscriptionAbstracts = t.array(TSubscriptionAbstract)
export type SubscriptionAbstracts = t.TypeOf<typeof TSubscriptionAbstracts>

const TSubscriptionCommon = t.intersection([
  t.strict({
    subscriptionId: t.string,
    name: t.string,
    type: TSubscriptionType,
    periodType: TPeriodTypes,
    createdAt: DateFromISOString,
  }),
  t.partial({
    used: t.boolean,
    currency: TPaymentCurrency,
    price: t.number,
    description: t.string,
    abstracts: TSubscriptionAbstracts,
    label: t.string,
    discount: t.number,
    discountDuration: t.number,
    trialEndedAt: DateFromISOString,
  }),
])

const TSubscriptionNoTrial = t.intersection([
  TSubscriptionCommon,
  t.strict({
    trialAvailable: t.literal(false),
  }),
])

const TSubscriptionWithTrial = t.intersection([
  TSubscriptionCommon,
  t.strict({
    trialAvailable: t.literal(true),
    trialDuration: t.number,
    trialPeriodType: TTrialPeriodTypes,
  }),
])

const TSubscriptionBase = t.union([
  TSubscriptionNoTrial,
  TSubscriptionWithTrial,
])

export const TSubscriptionRow = t.intersection([
  TSubscriptionBase,
  // FYI: temporary fix, requested by backend
  // TODO: change back to strict, when backend will be fixed
  t.partial({
    createdBy: t.string,
  }),
])

export const TSubscriptionDetails = t.intersection([
  TSubscriptionBase,
  t.strict({
    features: TSubscriptionFeatures,
  }),
])

const TCompanySubscriptionOther = t.intersection([
  t.strict({
    subscription: TSubscriptionDetails,
    status: t.union([t.literal('ACTIVE'), t.literal('CHANGING')]), // Other statuses
    periodFrom: DateFromISOString,
  }),
  t.partial({
    nextPeriodFrom: DateFromISOString,
    billingEmail: t.string,
  }),
])

const TCompanySubscriptionFrozen = t.strict({
  subscription: TSubscriptionDetails,
  status: t.literal('FROZEN'),
})

const TCompanySubscriptionCanceling = t.intersection([
  t.strict({
    subscription: TSubscriptionDetails,
    status: t.literal('CANCELING'),
  }),
  t.partial({
    cancelAt: DateFromISOString,
    periodFrom: DateFromISOString,
  }),
])

const TCompanySubscriptionTrial = t.strict({
  subscription: TSubscriptionDetails,
  status: t.literal('TRIAL'),
  periodFrom: DateFromISOString,
  nextPeriodFrom: DateFromISOString,
  trialEndedAt: DateFromISOString, // Only available when status is 'TRIAL'
})

export const TCompanySubscription = nullableCodec(
  t.union([
    TCompanySubscriptionTrial,
    TCompanySubscriptionCanceling,
    TCompanySubscriptionFrozen,
    TCompanySubscriptionOther,
  ]),
)

export type CompanySubscription = t.TypeOf<typeof TCompanySubscription>

export const TDefaultSubscription = t.strict({
  subscriptionId: t.string,
  name: t.string,
})

export const TNullableDefaultSubscription = nullableCodec(TDefaultSubscription)

export const TDefaultSubscriptionsList = t.array(TDefaultSubscription)
