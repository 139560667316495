import { isRequestError } from './error-types'

// Simple primitive version, but better than nothing
export const getRequestErrorMessage = (
  error: unknown,
  type: 'query' | 'mutation' = 'query',
) => {
  const unknownError = {
    title: 'An unknown error occurred',
    message: 'There was an error. Please try again later. That is all we know.',
  }

  if (isRequestError(error)) {
    switch (error.type) {
      case 'client': {
        return {
          title:
            type === 'query'
              ? 'Could not load data'
              : 'The action cannot be done',
          message: error.message,
        }
      }
      case 'network': {
        return {
          title: 'Connection error',
          message: 'Please check your connection and try again.',
        }
      }
      case 'server': {
        return {
          title: 'An unexpected error occurred',
          message:
            'The server encountered an error and could not complete your request.',
        }
      }
      case 'decode_body':
      case 'decode_headers': {
        return {
          title: 'Whoops, something went wrong',
          message: 'It is probably our mistake. Failed to decode the response',
        }
      }
      case 'parse_blob':
      case 'parse_json': {
        return {
          title: 'Whoops, something went wrong',
          message: 'It is probably our mistake. Failed to parse the response',
        }
      }
      default: {
        return unknownError
      }
    }
  }

  return unknownError
}
