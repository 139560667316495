import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export const IconJobsOutline = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 18 18">
    <path
      d="M4.5 1.6875H3.375C2.77826 1.6875 2.20597 1.92455 1.78401 2.34651C1.36205 2.76847 1.125 3.34076 1.125 3.9375V15.75C1.125 16.3467 1.36205 16.919 1.78401 17.341C2.20597 17.7629 2.77826 18 3.375 18H14.625C15.2217 18 15.794 17.7629 16.216 17.341C16.6379 16.919 16.875 16.3467 16.875 15.75V3.9375C16.875 3.34076 16.6379 2.76847 16.216 2.34651C15.794 1.92455 15.2217 1.6875 14.625 1.6875H13.5V2.8125H14.625C14.9234 2.8125 15.2095 2.93103 15.4205 3.142C15.6315 3.35298 15.75 3.63913 15.75 3.9375V15.75C15.75 16.0484 15.6315 16.3345 15.4205 16.5455C15.2095 16.7565 14.9234 16.875 14.625 16.875H3.375C3.07663 16.875 2.79048 16.7565 2.5795 16.5455C2.36853 16.3345 2.25 16.0484 2.25 15.75V3.9375C2.25 3.63913 2.36853 3.35298 2.5795 3.142C2.79048 2.93103 3.07663 2.8125 3.375 2.8125H4.5V1.6875Z"
      fill="currentColor"
    />
    <path
      d="M10.6875 1.125C10.8367 1.125 10.9798 1.18426 11.0852 1.28975C11.1907 1.39524 11.25 1.53832 11.25 1.6875V2.8125C11.25 2.96168 11.1907 3.10476 11.0852 3.21025C10.9798 3.31574 10.8367 3.375 10.6875 3.375H7.3125C7.16332 3.375 7.02024 3.31574 6.91475 3.21025C6.80926 3.10476 6.75 2.96168 6.75 2.8125V1.6875C6.75 1.53832 6.80926 1.39524 6.91475 1.28975C7.02024 1.18426 7.16332 1.125 7.3125 1.125H10.6875ZM7.3125 0C6.86495 0 6.43572 0.17779 6.11926 0.494257C5.80279 0.810725 5.625 1.23995 5.625 1.6875V2.8125C5.625 3.26005 5.80279 3.68928 6.11926 4.00574C6.43572 4.32221 6.86495 4.5 7.3125 4.5H10.6875C11.1351 4.5 11.5643 4.32221 11.8807 4.00574C12.1972 3.68928 12.375 3.26005 12.375 2.8125V1.6875C12.375 1.23995 12.1972 0.810725 11.8807 0.494257C11.5643 0.17779 11.1351 0 10.6875 0L7.3125 0Z"
      fill="currentColor"
    />
    <path
      d="M8.99953 10.7046C9.64516 10.7046 10.2643 10.4481 10.7209 9.99161C11.1774 9.53508 11.4339 8.9159 11.4339 8.27027C11.4339 7.62465 11.1774 7.00547 10.7209 6.54894C10.2643 6.09241 9.64516 5.83594 8.99953 5.83594C8.35391 5.83594 7.73472 6.09241 7.2782 6.54894C6.82167 7.00547 6.5652 7.62465 6.5652 8.27027C6.5652 8.9159 6.82167 9.53508 7.2782 9.99161C7.73472 10.4481 8.35391 10.7046 8.99953 10.7046ZM4.94231 15.5733C4.94231 15.5733 4.13086 15.5733 4.13086 14.7618C4.13086 13.9504 4.94231 11.5161 8.99953 11.5161C13.0568 11.5161 13.8682 13.9504 13.8682 14.7618C13.8682 15.5733 13.0568 15.5733 13.0568 15.5733H4.94231Z"
      fill="currentColor"
    />
  </SvgIcon>
)
