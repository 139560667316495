import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export const IconEyeCloseOutline = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 17 15">
    <path
      d="M8.53931 4.00977C11.3945 4.00977 15.0786 7.27942 15.0786 7.27942C15.0786 7.27942 11.3945 10.5491 8.53931 10.5491C5.68412 10.5491 2 7.27942 2 7.27942C2 7.27942 5.68412 4.00977 8.53931 4.00977Z"
      fill="none"
      stroke="currentColor"
      strokeWidth="1.4"
    />
    <circle cx="8.53916" cy="7.27841" r="2.45224" fill="currentColor" />
    <path
      d="M12.2579 13.2442C12.7233 13.7455 13.5083 13.7708 14.0051 13.3005C14.4922 12.8393 14.5201 12.073 14.0678 11.5776L4.57768 1.18377C4.10128 0.662015 3.2937 0.620863 2.76674 1.09149C2.22835 1.57232 2.18959 2.40145 2.68077 2.93042L12.2579 13.2442Z"
      fill="currentColor"
      stroke="#EFEFEF"
    />
  </SvgIcon>
)
