import {
  PaymentCurrency,
  PeriodTypes,
  SubscriptionType,
  TrialPeriodTypes,
} from 'api'

export const subscriptionTypes: Record<SubscriptionType, string> = {
  PUBLIC: 'Public',
  PRIVATE: 'Non-public',
  UNLIMITED: 'Unlimited',
}

export const periodTypes: Record<PeriodTypes, string> = {
  MONTH: 'Month',
}

export const trialPeriodTypes: Record<TrialPeriodTypes, string> = {
  DAY: 'Day',
  MONTH: 'Month',
  YEAR: 'Year',
}

export const paymentCurrency: Record<
  PaymentCurrency,
  { label: string; symbol: string }
> = {
  EUR: { label: 'EUR', symbol: '€' },
}
