import {
  ArrayParam as ArrayParamConfig,
  BooleanParam as BooleanParamConfig,
  DateParam as DateParamConfig,
  NumberParam as NumberParamConfig,
  QueryParamConfig,
  StringParam as StringParamConfig,
  withDefault,
} from 'use-query-params'

// https://github.com/pbeshai/use-query-params/issues/165
// Instead of complicating things with null and undefined to handle empty values,
// We always use `withDefault` utility to keep the type of state consistent.
// An empty value for string is an empty string '', number - 0, boolean - false, array - []
// The only exception is Date - it's empty value is null

export const StringParam = withDefault(
  StringParamConfig,
  '',
) as QueryParamConfig<string>

export const NumberParam = withDefault(
  NumberParamConfig,
  0,
) as QueryParamConfig<number>

export const BooleanParam = withDefault(
  BooleanParamConfig,
  false,
) as QueryParamConfig<boolean>

export const ArrayParam = withDefault(ArrayParamConfig, []) as QueryParamConfig<
  Array<string>
>

export const DateParam = withDefault(
  DateParamConfig,
  null,
) as QueryParamConfig<Date | null>
