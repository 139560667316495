import { paths, unauthRoutes } from 'app/routes'
import { useRedirectAuth } from 'lib/react-utils'
import { Navigate, useRoutes } from 'react-router-dom'

import { UnauthLayout } from './unauth-layout'

export const UnauthApp = () => {
  const { rememberAuthUrl } = useRedirectAuth()

  return (
    <UnauthLayout>
      {useRoutes([
        ...unauthRoutes,
        {
          path: '*',
          element: <Navigate replace to={rememberAuthUrl(paths.login)} />,
        },
      ])}
    </UnauthLayout>
  )
}
