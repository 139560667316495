import { Box, Paper } from '@mui/material'
import { FormEvent } from 'react'

type Props = {
  onSubmit: () => void
  children: React.ReactNode
  toolbar?: React.ReactNode
}

export const FormLayout = ({ onSubmit, children, toolbar }: Props) => {
  return (
    <Box
      component="form"
      noValidate
      display="flex"
      flexDirection="column"
      height="100%"
      onSubmit={(event: FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        onSubmit()
      }}
    >
      {toolbar}
      <Box overflow="auto" p={3}>
        <Paper sx={{ p: 5 }}>
          <Box maxWidth={508}>{children} </Box>
        </Paper>
      </Box>
    </Box>
  )
}
