import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export const IconTimeOutline = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.0002 20.9335C16.9339 20.9335 20.9335 16.9339 20.9335 12.0002C20.9335 7.06642 16.9339 3.06683 12.0002 3.06683C7.06642 3.06683 3.06683 7.06642 3.06683 12.0002C3.06683 16.9339 7.06642 20.9335 12.0002 20.9335ZM12.0002 22.6668C17.8912 22.6668 22.6668 17.8912 22.6668 12.0002C22.6668 6.10913 17.8912 1.3335 12.0002 1.3335C6.10913 1.3335 1.3335 6.10913 1.3335 12.0002C1.3335 17.8912 6.10913 22.6668 12.0002 22.6668Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.3305 5.16895C11.8092 5.16895 12.1972 5.55697 12.1972 6.03561V12.7585L16.3895 15.1455C16.8054 15.3824 16.9506 15.9116 16.7138 16.3275C16.4769 16.7434 15.9477 16.8886 15.5318 16.6518L11.1038 14.1305C10.7082 13.9053 10.4639 13.485 10.4639 13.0298V6.03561C10.4639 5.55697 10.8519 5.16895 11.3305 5.16895Z"
      fill="currentColor"
    />
  </SvgIcon>
)
