import { LinearProgress } from '@mui/material'
import { useIsFetching, useIsMutating } from '@tanstack/react-query'

/**
 * Displays a global loading indicator while:
 * - Any query is fetching (hard loading / background update).
 * - Any mutation is running.
 */
export const GlobalLoadingIndicator = () => {
  const queries = useIsFetching()
  const mutations = useIsMutating()

  if (queries === 0 && mutations === 0) {
    return null
  }

  return (
    <LinearProgress
      sx={{
        position: 'absolute',
        zIndex: 9999,
        top: 0,
        left: 0,
        right: 0,
        height: '2px',
      }}
    />
  )
}
