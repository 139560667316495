import { createListSearchParams, ListOrder } from 'api/list-params'
import { pipe } from 'fp-ts/lib/function'
import { decodeJson, get, put } from 'lib/request'

import { TEmailsList } from './emails.codecs'

export type GetEmailsListInput = {
  order: ListOrder
}

export const getEmailsList = async ({ order }: GetEmailsListInput) => {
  const params = createListSearchParams({ order })

  return pipe(
    await get('backoffice/emails', { query: params }),
    decodeJson(TEmailsList),
  )
}

export const toggleEmailActivation = async ({
  emailId,
  active,
}: {
  emailId: string
  active: boolean
}) => {
  return await put('backoffice/emails/:emailId', {
    params: { emailId },
    query: new URLSearchParams({
      active: active.toString(),
    }),
  })
}

export const updateEmailRecipients = async ({
  emailId,
  recipients,
}: {
  emailId: string
  recipients: Array<string>
}) => {
  return await put('backoffice/emails/:emailId/recipients', {
    params: { emailId },
    body: recipients,
  })
}
