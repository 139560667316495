import { createTheme } from '@mui/material'

const gilroy = 'Gilroy, sans-serif'

export const theme = createTheme({
  palette: {
    primary: {
      main: '#0043F0',
    },
    success: {
      main: '#008D6E',
    },
    text: {
      primary: '#000E3E',
      secondary: '#4D5678',
      disabled: '#9EA3B6',
    },
    background: {
      default: '#F9FAFC',
    },
    divider: '#EDEEF2',
    error: {
      main: '#C70008',
    },
    info: {
      main: '#1F68FA',
    },
    warning: {
      main: '#FBA930',
    },
    grey: {
      50: '#F8F8F8',
      100: '#ECECEC',
      200: '#D6D6D6',
      300: '#C4C4C4',
      400: '#979797',
      500: '#5D6A7E',
    },
    badge: {
      main: '#E6F3FE',
    },
    orange: {
      main: '#FF8200',
    },
    greyBlue: {
      light: '#9EA3B6',
      main: '#4D5678',
    },
    blue: {
      main: '#008BF0',
      contrastText: '#FFF',
    },
    lavenderBlush: '#FEE6E7',
    flushOrange: '#FF8200',
    black: '#000000',
    martinique: '#322D51',
    selago: '#F1EBFD',
  },

  components: {
    MuiPaper: {
      defaultProps: {
        variant: 'outlined',
      },
      variants: [
        {
          props: { variant: 'elevation' },
          style: {
            boxShadow:
              '0px 1px 1px rgba(0, 0, 0, 0.05), 0px 1px 4px rgba(50, 50, 105, 0.16)',
          },
        },
      ],
    },
  },

  typography: {
    fontFamily: 'GT-America, sans-serif',
  },
})

const { pxToRem, fontFamily } = theme.typography

theme.typography = {
  ...theme.typography,

  h1: {
    fontFamily: gilroy,
    fontSize: pxToRem(20),
    lineHeight: pxToRem(28),
    fontWeight: 600,
    letterSpacing: '0.68px',
  },

  h2: {
    fontFamily: gilroy,
    fontSize: pxToRem(18),
    lineHeight: pxToRem(24),
    fontWeight: 600,
    letterSpacing: '0.52px',
  },

  h3: {
    fontFamily: gilroy,
    fontSize: pxToRem(16),
    lineHeight: pxToRem(21),
    fontWeight: 600,
    letterSpacing: '0.45px',
  },

  h4: {
    fontFamily: gilroy,
    fontSize: pxToRem(14),
    lineHeight: pxToRem(21),
    fontWeight: 600,
    letterSpacing: '0.45px',
  },

  body1: {
    fontFamily,
    fontSize: pxToRem(15),
    lineHeight: pxToRem(19),
    fontWeight: 400,
    letterSpacing: '0.52px',
  },

  body2: {
    fontFamily,
    fontSize: pxToRem(14),
    lineHeight: pxToRem(18),
    fontWeight: 400,
    letterSpacing: '0.52px',
  },

  caption: {
    fontFamily,
    display: 'inline-block',
    fontSize: pxToRem(12),
    lineHeight: pxToRem(16),
    fontWeight: 400,
    letterSpacing: '0.52px',
  },

  button: {
    fontFamily: gilroy,
    fontSize: pxToRem(10),
    lineHeight: pxToRem(20),
    fontWeight: 700,
    letterSpacing: '0.6px',
    textTransform: 'uppercase',
  },
}

theme.components = {
  ...theme.components,

  MuiButton: {
    defaultProps: {
      disableElevation: true,
    },
    variants: [
      {
        props: { variant: 'contained' },
        style: {
          boxShadow:
            '0px 1px 3px -1px rgba(0, 0, 0, 0.3), 0px 2px 5px -1px rgba(50, 50, 93, 0.25)',
          '&:hover': {
            boxShadow:
              '0px 2px 3px -1px rgba(0, 0, 0, 0.3), 0px 3px 6px -1px rgba(50, 50, 93, 0.25)',
          },
          '&:focus': {
            boxShadow:
              '0px 2px 3px -1px rgba(0, 0, 0, 0.3), 0px 3px 6px -1px rgba(50, 50, 93, 0.25)',
          },
        },
      },
      {
        props: { variant: 'outlined' },
        style: {
          boxShadow:
            '0px 1px 3px -1px rgba(0, 0, 0, 0.15), 0px 2px 5px -1px rgba(50, 50, 93, 0.12)',
          '&:hover': {
            boxShadow:
              '0px 2px 3px -1px rgba(0, 0, 0, 0.15), 0px 3px 4px -1px rgba(50, 50, 93, 0.12)',
          },
          '&:focus': {
            boxShadow:
              '0px 2px 3px -1px rgba(0, 0, 0, 0.15), 0px 3px 4px -1px rgba(50, 50, 93, 0.12)',
          },
        },
      },
      {
        props: { variant: 'outlined', color: 'greyBlue' },
        style: {
          borderColor: '#EDEEF2',
          '&:hover': {
            borderColor: '#EDEEF2',
          },
        },
      },
    ],
  },

  MuiInputBase: {
    styleOverrides: {
      root: {
        border: '1px solid',
        borderColor: theme.palette.divider,
        boxShadow: 'inset 0px 1px 4px rgba(0, 14, 62, 0.04)',

        '&:hover:not(.Mui-disabled)': {
          boxShadow:
            'inset 0px 1px 4px rgba(0, 14, 62, 0.04), 0px 1px 2px rgba(77, 86, 120, 0.1)',
        },
      },
    },
  },

  MuiInputLabel: {
    styleOverrides: {
      root: {
        color: theme.palette.text.primary,
      },
    },
  },
}
