import { Box } from '@mui/material'
import { paths } from 'app/routes'
import notFoundImage from 'assets/images/404.png'
import { NoDataTemplate } from 'components/templates'
import { useTranslation } from 'react-i18next'
import { LinkButton } from 'ui/navigation/link'

export const NotFound = () => {
  const { t } = useTranslation()

  return (
    <Box py={9} px={3}>
      <NoDataTemplate
        imgSrc={notFoundImage}
        imgWidth={340}
        title={t('main.page_not_found')}
        subtitle={t('main.page_not_found_message')}
        button={
          <LinkButton to={paths.companies}>{t('main.back_to_home')}</LinkButton>
        }
      />
    </Box>
  )
}
