import 'normalize.css'
import 'styles/globals.css'
import 'styles/fonts.css'
import 'react-toastify/dist/ReactToastify.css'
import 'styles/freakflags.css'
import './lib/form-utils/error-map'

import { ThemeProvider } from '@mui/material'
import CssBaseline from '@mui/material/CssBaseline'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { AuthProvider } from 'app/auth'
import { env } from 'app/env'
import { initI18n } from 'app/i18n'
import { theme } from 'app/mui-theme'
import { useGlobalQueryClient } from 'app/query-client'
import { GlobalLoadingIndicator, RuntimeErrorDialog } from 'components/global'
import { StrictModeConditional } from 'lib/app-helpers'
import { Suspense } from 'react'
import { createRoot } from 'react-dom/client'
import { ErrorBoundary } from 'react-error-boundary'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import { BrowserRouter } from 'react-router-dom'
import { ToastContainer } from 'ui/feedback'
import { QueryParamProvider } from 'use-query-params'
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6'

import { Root } from './root'

const setupMSW = () => {
  if (env.REACT_APP_MSW === 'off' || process.env.NODE_ENV !== 'development')
    return

  const { worker } = require('mocks/browser')
  worker.start({
    quiet: env.REACT_APP_MSW === 'quiet',
  })
}

const setupI18n = async (): Promise<void> => {
  await initI18n({
    debug: env.REACT_APP_DEBUG_I18N === 'on',
  })
}

const App = () => {
  const { t } = useTranslation()
  const globalQueryClient = useGlobalQueryClient()

  return (
    <Suspense fallback={null}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Helmet
          defaultTitle={t('main.axterior_back_office')}
          titleTemplate={`%s · ${t('main.axterior_back_office')}`}
        />
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <QueryClientProvider client={globalQueryClient}>
            <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
            <GlobalLoadingIndicator />
            <AuthProvider>
              <BrowserRouter>
                <StrictModeConditional>
                  <QueryParamProvider
                    adapter={ReactRouter6Adapter}
                    options={{
                      removeDefaultsFromUrl: true,
                    }}
                  >
                    <ErrorBoundary FallbackComponent={RuntimeErrorDialog}>
                      <Root />
                    </ErrorBoundary>
                  </QueryParamProvider>
                </StrictModeConditional>
              </BrowserRouter>
            </AuthProvider>
            <ToastContainer />
          </QueryClientProvider>
        </LocalizationProvider>
      </ThemeProvider>
    </Suspense>
  )
}

const main = async () => {
  setupMSW()
  await setupI18n()

  const container = document.querySelector('#root')
  const root = createRoot(container!)

  root.render(<App />)
}

void main()
