import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export const IconSubscription = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 24 24">
    <path
      d="M17.4917 10.4462H23.6017C23.6755 10.4462 23.7478 10.4673 23.8101 10.5069C23.8724 10.5465 23.9221 10.6031 23.9534 10.6699C23.9847 10.7368 23.9963 10.8111 23.9869 10.8843C23.9774 10.9576 23.9473 11.0266 23.9001 11.0833L20.8451 14.7505C20.8086 14.7942 20.763 14.8294 20.7115 14.8535C20.6599 14.8777 20.6037 14.8902 20.5467 14.8902C20.4898 14.8902 20.4336 14.8777 20.382 14.8535C20.3305 14.8294 20.2848 14.7942 20.2484 14.7505L17.1934 11.0833C17.1462 11.0266 17.116 10.9576 17.1066 10.8843C17.0972 10.8111 17.1088 10.7368 17.1401 10.6699C17.1714 10.6031 17.2211 10.5465 17.2834 10.5069C17.3456 10.4673 17.4179 10.4462 17.4917 10.4462ZM0.398709 13.554H6.50869C6.58251 13.554 6.65479 13.5329 6.71707 13.4933C6.77935 13.4537 6.82906 13.3971 6.86036 13.3303C6.89167 13.2634 6.90328 13.189 6.89383 13.1158C6.88439 13.0426 6.85429 12.9736 6.80704 12.9169L3.75205 9.24966C3.71559 9.20594 3.66997 9.17077 3.61841 9.14663C3.56686 9.1225 3.51063 9.10999 3.4537 9.10999C3.39677 9.10999 3.34054 9.1225 3.28899 9.14663C3.23743 9.17077 3.19181 9.20594 3.15535 9.24966L0.100358 12.9169C0.053115 12.9736 0.02301 13.0426 0.0135676 13.1158C0.00412524 13.189 0.0157364 13.2634 0.0470415 13.3303C0.0783466 13.3971 0.12805 13.4537 0.190331 13.4933C0.252612 13.5329 0.324892 13.554 0.398709 13.554Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.9998 4.23047C9.58813 4.23047 7.4313 5.32909 6.00636 7.05549C5.94273 7.13796 5.86317 7.20679 5.7724 7.2579C5.68164 7.309 5.58152 7.34134 5.47801 7.35297C5.3745 7.36461 5.26971 7.35532 5.16986 7.32564C5.07001 7.29597 4.97715 7.24652 4.8968 7.18023C4.81645 7.11395 4.75024 7.03219 4.70212 6.9398C4.654 6.84742 4.62495 6.74631 4.61669 6.64247C4.60844 6.53863 4.62114 6.4342 4.65406 6.33537C4.68697 6.23655 4.73942 6.14535 4.80829 6.0672C5.94605 4.6897 7.45552 3.66797 9.15717 3.12355C10.8588 2.57913 12.681 2.53492 14.4071 2.99618C16.1331 3.45744 17.6904 4.40477 18.8936 5.72547C20.0968 7.04618 20.8954 8.68469 21.1943 10.4461H19.614C19.2552 8.69146 18.3015 7.11452 16.9141 5.98196C15.5267 4.84939 13.7908 4.23069 11.9998 4.23047ZM4.38563 13.5539C4.6798 14.9907 5.37446 16.315 6.38936 17.3737C7.40426 18.4324 8.69798 19.1824 10.1211 19.537C11.5441 19.8915 13.0385 19.8363 14.4315 19.3776C15.8245 18.9188 17.0593 18.0754 17.9932 16.9446C18.0569 16.8621 18.1364 16.7933 18.2272 16.7422C18.318 16.6911 18.4181 16.6587 18.5216 16.6471C18.6251 16.6355 18.7299 16.6448 18.8297 16.6744C18.9296 16.7041 19.0224 16.7536 19.1028 16.8198C19.1832 16.8861 19.2494 16.9679 19.2975 17.0603C19.3456 17.1526 19.3746 17.2538 19.3829 17.3576C19.3912 17.4614 19.3785 17.5659 19.3455 17.6647C19.3126 17.7635 19.2602 17.8547 19.1913 17.9329C18.0535 19.3104 16.5441 20.3321 14.8424 20.8765C13.1408 21.4209 11.3186 21.4651 9.59252 21.0039C7.86647 20.5426 6.30922 19.5953 5.106 18.2746C3.90278 16.9539 3.10422 15.3154 2.8053 13.5539H4.38563Z"
      fill="currentColor"
    />
    <path
      d="M14.9068 13.5785C14.9068 11.9359 13.7492 11.465 12.4969 11.1999L11.8331 11.0654C10.8516 10.8725 10.7758 10.5878 10.7758 10.2066C10.7758 9.7121 11.2215 9.41654 11.9681 9.41654C12.8522 9.41654 13.0787 9.86065 13.1727 10.1943L13.1816 10.222C13.2928 10.5129 13.5512 10.6864 13.8736 10.6864C13.9571 10.6864 14.0322 10.6737 14.0883 10.6623C14.4343 10.5934 14.6753 10.318 14.6753 9.99182C14.6753 9.91092 14.6606 9.8307 14.6312 9.75272C14.4539 9.1826 13.9557 8.28525 12.5754 8.08041V7.10267C12.5754 6.28829 11.2998 6.28829 11.2998 7.10267V8.08626C9.81703 8.3183 9.27101 9.32703 9.27101 10.2063C9.27101 11.8061 10.3851 12.2522 11.4733 12.4766L12.1913 12.6291C13.2138 12.8371 13.394 13.1037 13.394 13.5867C13.394 14.177 12.895 14.5288 12.0584 14.5288C10.9724 14.5288 10.7511 14.0398 10.603 13.4909C10.5164 13.187 10.2419 12.9913 9.90327 12.9913C9.82943 12.9913 9.77021 13.0011 9.69878 13.0133L9.67726 13.0175C9.32713 13.1006 9.09216 13.3764 9.09216 13.7033C9.09216 13.7654 9.10318 13.8183 9.11213 13.861L9.12315 13.9098C9.28065 14.4423 9.62837 15.605 11.3627 15.8645V16.8878C11.3627 17.2953 11.6832 17.5085 12.0003 17.5085C12.3173 17.5085 12.6382 17.2953 12.6382 16.8878V15.8824C14.0153 15.703 14.9082 14.8232 14.9082 13.5786"
      fill="currentColor"
    />
  </SvgIcon>
)
