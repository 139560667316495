import { env } from 'app/env'

import { buildRoute, Route } from './build-route'
import { paths } from './paths'

export const authRoutes: Array<Route> = [
  buildRoute({
    path: paths.companies,
    factory: () => import('pages/companies'),
  }),
  buildRoute({
    path: paths.companyProfile,
    factory: () => import('pages/companies/profile'),
    children: [
      buildRoute({
        path: paths.companyDetails,
        factory: () => import('pages/companies/profile/details'),
      }),
      buildRoute({
        path: paths.companyCharges,
        factory: () => import('pages/companies/profile/company-charges'),
      }),
      buildRoute({
        path: paths.companyUsers,
        factory: () => import('pages/companies/profile/users'),
      }),
    ],
  }),
  buildRoute({
    path: paths.companyUserDetails,
    factory: () => import('pages/companies/profile/user-details'),
  }),
  buildRoute({
    path: paths.promotions,
    factory: () => import('pages/promotions'),
  }),
  buildRoute({
    path: paths.createPromotion,
    factory: () => import('pages/promotions/create'),
  }),
  buildRoute({
    path: paths.editPromotion,
    factory: () => import('pages/promotions/edit'),
  }),
  buildRoute({
    path: paths.subscriptions,
    factory: () => import('pages/subscriptions'),
  }),
  buildRoute({
    path: paths.createSubscription,
    factory: () => import('pages/subscriptions/create'),
  }),
  buildRoute({
    path: paths.subscriptionDetails,
    factory: () => import('pages/subscriptions/details'),
  }),
  buildRoute({
    path: paths.editSubscription,
    factory: () => import('pages/subscriptions/edit'),
  }),
  buildRoute({
    path: paths.subscriptionsConfiguration,
    factory: () => import('pages/subscriptions-configuration'),
  }),
  buildRoute({
    path: paths.emails,
    factory: () => import('pages/emails'),
  }),
  buildRoute({
    path: paths.charges,
    factory: () => import('pages/charges'),
  }),

  ...(env.REACT_APP_ENV === 'development' &&
  window.location.hostname === 'localhost'
    ? [
        buildRoute({
          path: paths.iconsList,
          factory: () => import('pages/development/icons-list'),
          scopes: [],
        }),
      ]
    : []),
]
