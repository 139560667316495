import { Box, Stack, Tooltip, Typography } from '@mui/material'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { logout } from 'api'
import { useAuthContext } from 'app/auth'
import { env } from 'app/env'
import { paths } from 'app/routes'
import { IconLogoutOutline } from 'assets/icons'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { IconButton } from 'ui/inputs/icon-button'

import { NavigationItem } from './navigation-item'

export const AppSidebar = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const { unauthorize } = useAuthContext()

  const $logout = useMutation(logout)

  const navigationItems: ReadonlyArray<{
    route: string
    name: string
  }> = [
    { route: paths.companies, name: t('main.companies') },
    { route: paths.subscriptions, name: t('main.subscriptions') },
    {
      route: paths.subscriptionsConfiguration,
      name: t('main.subscriptions_configuration'),
    },
    {
      route: paths.promotions,
      name: t('main.promotions'),
    },
    { route: paths.emails, name: t('main.emails') },
    { route: paths.charges, name: t('main.charges') },
  ]

  return (
    <Box
      sx={{
        py: 2,
        px: 1.5,
        height: '100%',
        width: 250,
        bgcolor: 'white',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        borderRight: 1,
        borderColor: 'divider',
      }}
    >
      <Stack spacing={1}>
        <Typography variant="h3" fontWeight={600} px={1.5} mb={1}>
          {t('main.back_office')}
        </Typography>

        {navigationItems.map(item => (
          <NavigationItem key={item.name} name={item.name} to={item.route} />
        ))}
        {env.REACT_APP_ENV === 'development' &&
          window.location.hostname === 'localhost' && (
            <NavigationItem name="Icons" to={paths.iconsList} />
          )}
      </Stack>

      <Box display="flex" alignItems="center" justifyContent="flex-end">
        <Tooltip title={t('main.logout')}>
          <IconButton
            onClick={() =>
              $logout.mutate(undefined, {
                onSuccess: () => {
                  unauthorize()
                  queryClient.removeQueries()
                  navigate(paths.login)
                },
              })
            }
          >
            <IconLogoutOutline />
          </IconButton>
        </Tooltip>
      </Box>
    </Box>
  )
}
