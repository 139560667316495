import { Box, Divider, Typography, useTheme } from '@mui/material'
import {
  IconCheckCircleOutline,
  IconInfoCircleOutline,
  IconWarningOutline,
} from 'assets/icons'

type ToastType = 'success' | 'error' | 'warning' | 'info'

export type ToastContentProps = {
  title: string
  type?: ToastType
  body?: React.ReactNode
}

const getIcon = (type: ToastType) => {
  switch (type) {
    case 'success': {
      return IconCheckCircleOutline
    }
    case 'error':
    case 'warning': {
      return IconWarningOutline
    }
    case 'info': {
      return IconInfoCircleOutline
    }
  }
}

const useColor = (type: ToastType) => {
  const theme = useTheme()

  switch (type) {
    case 'success': {
      return theme.palette.primary.main
    }
    case 'error': {
      return theme.palette.error.main
    }
    case 'info': {
      return theme.palette.info.main
    }
    case 'warning': {
      return theme.palette.warning.main
    }
  }
}

export const ToastContent = ({
  title,
  type = 'success',
  body,
}: ToastContentProps) => {
  const color = useColor(type)
  const Icon = getIcon(type)

  return (
    <Box height="100%" display="flex" flexDirection="column">
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          minHeight: '42px',
          p: 1.5,
          pr: 5,
          position: 'relative',

          '&::before': {
            content: '""',
            position: 'absolute',
            left: '4px',
            top: '4px',
            bottom: '4px',
            width: '2px',
            background: color,
          },
        }}
      >
        <Box sx={{ color, mr: 1, display: 'flex' }}>
          <Icon sx={{ fontSize: '16px' }} />
        </Box>

        <Typography variant="body2" color="text.primary">
          {title}
        </Typography>
      </Box>

      {body && (
        <>
          <Divider />
          <Box
            component={typeof body === 'string' ? 'p' : 'div'}
            sx={{
              px: 1.5,
              py: 1,
              m: 0,
              overflow: 'auto',
              typography: 'body2',
              color: 'grey.500',
            }}
          >
            {body}
          </Box>
        </>
      )}
    </Box>
  )
}
