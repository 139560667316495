import { Box, Typography } from '@mui/material'
import { InfoHint } from 'components/common'
import { Button } from 'ui/inputs/button'

export type DialogContentProps = {
  title: string
  children: React.ReactNode
  onConfirm?: () => void
  onDeny: () => void
  hint?: string
  confirmText?: string
  denyText?: string
  isConfirmDisabled?: boolean
  variant?: 'success' | 'danger'
  disableContentPadding?: boolean
  wrapWithForm?: boolean
  isActionLoading?: boolean
}

export const DialogContent = ({
  title,
  disableContentPadding = false,
  denyText = 'Cancel',
  onDeny,
  hint,
  wrapWithForm = false,
  variant = 'success',
  isConfirmDisabled,
  confirmText = 'Confirm',
  onConfirm,
  children,
  isActionLoading = false,
}: DialogContentProps) => {
  const content = (
    <>
      <Box display="flex" px={3} py={2.5}>
        <Typography variant="h2" id={title}>
          {title}
        </Typography>

        {hint !== undefined && <InfoHint hint={hint} />}
      </Box>

      <Box p={disableContentPadding ? 0 : 3} pt={0}>
        {typeof children === 'string' ? (
          <Typography variant="body2" color="text.secondary">
            {children}
          </Typography>
        ) : (
          children
        )}
      </Box>

      <Box
        sx={{
          position: 'sticky',
          bottom: 0,
          bgcolor: '#FFF',
          px: 3,
          py: 2.5,
          display: 'flex',
          borderTop: 1,
          borderColor: 'divider',
        }}
      >
        <Button variant="outlined" color="greyBlue" onClick={onDeny}>
          {denyText}
        </Button>

        {onConfirm && (
          <Button
            sx={{ ml: 'auto' }}
            type={wrapWithForm ? 'submit' : 'button'}
            color={variant === 'danger' ? 'error' : 'primary'}
            onClick={wrapWithForm ? undefined : onConfirm}
            disabled={isConfirmDisabled}
            loading={isActionLoading}
          >
            {confirmText}
          </Button>
        )}
      </Box>
    </>
  )

  return wrapWithForm ? (
    <form
      noValidate
      onSubmit={event => {
        event.stopPropagation()
        event.preventDefault()
        onConfirm && onConfirm()
      }}
    >
      {content}
    </form>
  ) : (
    content
  )
}
