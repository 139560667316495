import { authRoutes, unauthRoutes } from 'app/routes'
import { useIsVisible } from 'lib/react-utils'
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
} from 'react'
import {
  Link,
  LinkProps,
  matchRoutes,
  NavLink,
  NavLinkProps,
  To,
} from 'react-router-dom'

/** Matches all routes against a given location (to) and preloads found ones */
const useChunksPreload = (to: To) => {
  const ref = useRef(null)
  const isVisible = useIsVisible(ref)

  const routes = useMemo(() => {
    if (!to) return []
    const matchedRoutes = matchRoutes([...authRoutes, ...unauthRoutes], to)
    return matchedRoutes ? matchedRoutes.map(({ route }) => route) : []
  }, [to])

  useEffect(() => {
    if (isVisible) {
      for (const route of routes) {
        route.preload?.()
      }
    }
  }, [isVisible, routes])

  return ref
}

export const RouteLink = forwardRef((props: LinkProps, forwardedRef) => {
  const innerRef = useChunksPreload(props.to)
  useImperativeHandle(forwardedRef, () => innerRef.current)
  return <Link ref={innerRef} {...props} />
})

export const NavRouteLink = forwardRef((props: NavLinkProps, forwardedRef) => {
  const innerRef = useChunksPreload(props.to)
  useImperativeHandle(forwardedRef, () => innerRef.current)
  return <NavLink ref={innerRef} {...props} />
})
