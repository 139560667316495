import { NavLink } from 'ui/navigation'

export const NavigationItem = (props: { to: string; name: string }) => {
  return (
    <NavLink
      to={props.to}
      sx={{
        width: '100%',
        height: '32px',
        px: 1.5,
        borderRadius: '2px',
        color: 'greyBlue.main',
        display: 'flex',
        alignItems: 'center',
        typography: 'body2',

        '&:hover, &.active': {
          bgcolor: '#E6ECFD',
          color: '#0043F0',
        },
      }}
    >
      {props.name}
    </NavLink>
  )
}
