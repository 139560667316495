import { useCallback } from 'react'
import {
  createSearchParams,
  To,
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router-dom'

export const useRedirectAuth = () => {
  const location = useLocation()
  const navigate = useNavigate()

  const REDIRECT_PATH = 'redirect-path'
  const REDIRECT_QUERY = 'redirect-query'

  const [searchParams] = useSearchParams()
  const redirectPath = searchParams.get(REDIRECT_PATH)
  const redirectQuery = searchParams.get(REDIRECT_QUERY)

  const rememberAuthUrl = useCallback(
    (loginPath: string) => {
      if (location.pathname === '/') {
        return loginPath
      }

      const redirectParams = createSearchParams({
        [REDIRECT_PATH]: location.pathname,
        ...(location.search && {
          [REDIRECT_QUERY]: location.search.slice(1),
        }),
      })

      return `${loginPath}?${redirectParams.toString()}`
    },
    [location.pathname, location.search],
  )

  const redirectToRememberedAuthUrl = useCallback(
    (alternativeUrl: To) => {
      navigate(
        redirectPath
          ? {
              pathname: redirectPath,
              search: redirectQuery ? `?${redirectQuery}` : '',
            }
          : alternativeUrl,
      )
    },
    [navigate, redirectPath, redirectQuery],
  )

  return {
    rememberAuthUrl,
    redirectToRememberedAuthUrl,
  }
}
