import { toArray } from 'fp-ts/Record'

type Label = string | { label: string }

export const recordToOptions = <T extends string>(
  record: Record<T, Label>,
): Array<{
  value: T
  label: string
}> => {
  return toArray(record).map(([key, descriptor]) => ({
    value: key,
    label: typeof descriptor === 'string' ? descriptor : descriptor.label,
  }))
}
