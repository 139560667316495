import { Box } from '@mui/material'
import { UseQueryResult } from '@tanstack/react-query'
import { ErrorTemplate } from 'components/templates'
import { CircularProgress } from 'ui/feedback'

/**
 * An utility function that is often used to render JSX for different query result states
 * @param query query result returned from `useQuery` call
 * @param states render props functions to render common query states:
 * - success (required)
 * - loading (optional, recommended)
 * - error (optional, recommended)
 * - fetching (optional)
 * @returns An appropriate query state or `null` otherwise
 */
export const renderQueryResult = <T extends any>(
  query: UseQueryResult<T, unknown>,
  {
    success,
    loading = () => (
      <Box height={500} position="relative">
        <CircularProgress centered />
      </Box>
    ),
    error = error => (
      <Box p={5}>
        <ErrorTemplate error={error} />
      </Box>
    ),
    fetching,
  }: {
    success: (data: T) => React.ReactNode
    loading?: () => React.ReactNode
    error?: (error: unknown) => React.ReactNode
    fetching?: () => React.ReactNode
  },
) => {
  if (query.isLoading && loading) {
    return loading()
  }

  if (query.isFetching && fetching) {
    return fetching()
  }

  if (query.isError && error) {
    return error(query.error)
  }

  if (query.isSuccess) {
    return success(query.data)
  }

  return null
}

export const renderAllQueriesResult = <
  Queries extends Array<UseQueryResult<any>>,
>(
  queries: Queries & { readonly 0: UseQueryResult<any> },
  {
    success,
    loading = () => (
      <Box height={500} position="relative">
        <CircularProgress centered />
      </Box>
    ),
    error = error => (
      <Box p={5}>
        <ErrorTemplate error={error} />
      </Box>
    ),
    fetching,
  }: {
    success: (data: {
      [Index in keyof Queries]: Queries[Index] extends UseQueryResult<
        infer Data,
        unknown
      >
        ? Data
        : never
    }) => JSX.Element | null
    loading?: () => JSX.Element | null
    error?: (error: unknown) => JSX.Element | null
    fetching?: () => JSX.Element | null
  },
) => {
  if (queries.some(query => query.isLoading) && loading) {
    return loading()
  }

  if (queries.some(query => query.isFetching) && fetching) {
    return fetching()
  }

  const queryWithError = queries.find(query => query.isError)

  if (queryWithError && error) {
    return error(queryWithError.error)
  }

  if (queries.every(query => query.isSuccess)) {
    return success(queries.map(query => query.data) as any)
  }

  return null
}
