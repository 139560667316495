import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export const IconUploadFileOutline = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 48 32">
    <path
      d="M38.7 12.08C37.34 5.18 31.28 0 24 0C18.22 0 13.2 3.28 10.7 8.08C4.68 8.72 0 13.82 0 20C0 23.1826 1.26428 26.2349 3.51472 28.4853C5.76515 30.7357 8.8174 32 12 32H38C39.3132 32 40.6136 31.7414 41.8268 31.2388C43.0401 30.7363 44.1425 29.9997 45.0711 29.0711C45.9997 28.1425 46.7362 27.0401 47.2388 25.8268C47.7413 24.6136 48 23.3132 48 22C48 16.72 43.9 12.44 38.7 12.08ZM38 28H12C9.87827 28 7.84344 27.1572 6.34315 25.6569C4.84285 24.1566 4 22.1217 4 20C4 15.9 7.06 12.48 11.12 12.06L13.26 11.84L14.26 9.94C16.16 6.28 19.88 4 24 4C29.24 4 33.76 7.72 34.78 12.86L35.38 15.86L38.44 16.08C41.56 16.28 44 18.9 44 22C44 23.5913 43.3679 25.1174 42.2426 26.2426C41.1174 27.3679 39.5913 28 38 28ZM16 18H21.1V24H26.9V18H32L24 10L16 18Z"
      fill="currentColor"
    />
  </SvgIcon>
)
