import {
  createListSearchParams,
  ListOrder,
  ListPagination,
} from 'api/list-params'
import { pipe } from 'fp-ts/function'
import {
  decodeJson,
  decodeJsonWithTotal,
  del,
  get,
  patch,
  post,
} from 'lib/request'

import {
  CompanyStatus,
  TChargeAttempt,
  TCompany,
  TCompanyCharge,
  TCompanyFullDetails,
  TCompanyUser,
} from './companies.codecs'

export type GetCompaniesListInput = {
  pagination: ListPagination
  order: ListOrder
  statuses: Array<CompanyStatus>
}

export const getCompaniesList = async ({
  pagination,
  order,
  statuses,
}: GetCompaniesListInput) => {
  const params = createListSearchParams({
    pagination,
    order,
  })

  if (statuses.length > 0) {
    for (const status of statuses) {
      params.append('statuses', status)
    }
  } else {
    params.set('statuses', '')
  }

  return pipe(
    await get('backoffice/companies', { query: params }),
    decodeJsonWithTotal(TCompany, pagination),
  )
}

export type GetCompanyDetailsInput = {
  companyId: string
}

export const getCompanyDetails = async ({
  companyId,
}: GetCompanyDetailsInput) => {
  return pipe(
    await get('backoffice/companies/:companyId', {
      params: { companyId },
    }),
    decodeJson(TCompanyFullDetails),
  )
}

export const changeCompanyStatus = async ({
  companyId,
  active,
}: {
  companyId: string
  active: boolean
}) => {
  return await patch('backoffice/companies/:companyId/status', {
    params: { companyId },
    query: new URLSearchParams({ active: active.toString() }),
  })
}

export const deleteCompany = async ({ companyId }: { companyId: string }) => {
  return await del('backoffice/companies/:companyId', {
    params: { companyId },
  })
}

export const finishTrialPeriodForCompany = async ({
  companyId,
}: {
  companyId: string
}) => {
  return await post('backoffice/companies/:companyId/trial', {
    params: { companyId },
  })
}

export type GetCompanyUsersListInput = {
  companyId: string
  pagination: ListPagination
  order: ListOrder
}

export const getCompanyUsersList = async ({
  order,
  companyId,
  pagination,
}: GetCompanyUsersListInput) => {
  const params = createListSearchParams({
    pagination,
    order,
  })

  return pipe(
    await get('backoffice/companies/:companyId/users', {
      query: params,
      params: { companyId },
    }),
    decodeJsonWithTotal(TCompanyUser, pagination),
  )
}

export type GetCompanyChargesListInput = {
  companyId?: string
  pagination: ListPagination
  order: ListOrder
}

export const getCompanyChargesList = async ({
  order,
  companyId,
  pagination,
}: GetCompanyChargesListInput) => {
  const params = createListSearchParams({
    pagination,
    order,
  })

  if (companyId) {
    params.set('companyId', companyId)
  }

  return pipe(
    await get('backoffice/companies/charges', {
      query: params,
    }),
    decodeJsonWithTotal(TCompanyCharge, pagination),
  )
}

export type GetChargeAttemptsListInput = {
  chargeId: string
  pagination: ListPagination
  order: ListOrder
}

export const getChargeAttemptsList = async ({
  order,
  chargeId,
  pagination,
}: GetChargeAttemptsListInput) => {
  const params = createListSearchParams({
    pagination,
    order,
  })

  return pipe(
    await get(`backoffice/companies/charges/:chargeId/attempts`, {
      query: params,
      params: { chargeId },
    }),
    decodeJsonWithTotal(TChargeAttempt, pagination),
  )
}
