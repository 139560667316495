import { ListPagination } from 'api'
import { array, Mixed, strict, TypeOf } from 'io-ts'
import { NumberFromString } from 'io-ts-types'

import { decodeHeaders } from './decode-headers'
import { decodeJson } from './decode-json'

export type JsonArrayWithTotal<T> = {
  rows: Array<T>
  total: number
  nextPage?: number
}

export const decodeJsonWithTotal = <TCodec extends Mixed>(
  codec: TCodec,
  pagination: ListPagination,
  // Backend still returns different count header
  name: 'count' | 'x-total-count' = 'count',
) => {
  type Result = JsonArrayWithTotal<TypeOf<TCodec>>

  return async (response: Response): Promise<Result> => {
    const headers = decodeHeaders(
      strict({
        [name]: NumberFromString,
      }),
    )(response)

    const rows = await decodeJson(array(codec))(response)
    const total = headers[name] ?? 0

    return {
      rows,
      total,
      nextPage:
        total > pagination.page * pagination.pageSize + rows.length
          ? pagination.page + 1
          : undefined,
    }
  }
}
