import { LoadingButton, LoadingButtonProps } from '@mui/lab'
import { styled } from '@mui/material'
import { ConfirmDialogProps, useConfirmationDialog } from 'lib/app-helpers'
import { ForwardedRef, forwardRef, MouseEvent } from 'react'

type StyledButtonProps<C extends React.ElementType> = LoadingButtonProps<
  C,
  { component?: C }
> & {
  /** When button content contains only icon */
  icon?: boolean
  /** Font size of start/end icon */
  iconSize?: string
  disableShadow?: boolean
  inputsEndAdornment?: boolean
}

const StyledButton = styled(LoadingButton, {
  shouldForwardProp: prop =>
    prop !== 'icon' &&
    prop !== 'iconSize' &&
    prop !== 'disableShadow' &&
    prop !== 'inputsEndAdornment',
})(
  <C extends React.ElementType>({
    theme,
    icon,
    iconSize = '20px',
    disableShadow = false,
    inputsEndAdornment = false,
  }: StyledButtonProps<C>) => ({
    minWidth: 'auto',
    height: theme.spacing(4),
    padding: theme.spacing(0.75, 2),
    borderRadius: '2px',

    '& .MuiButton-startIcon': {
      marginRight: theme.spacing(0.75),

      // override start/end icon's font size
      // mui button does not provide this ability via props or styles
      '& >*:nth-of-type(1)': {
        fontSize: iconSize,
      },
    },

    '& .MuiButton-endIcon': {
      marginLeft: theme.spacing(0.75),

      '& >*:nth-of-type(1)': {
        fontSize: iconSize,
      },
    },

    ...(icon && {
      width: theme.spacing(4),
      padding: theme.spacing(0.5),
    }),

    ...(disableShadow && {
      boxShadow: 'none',
      '&:hover': {
        boxShadow: 'none',
      },
      '&:focus': {
        boxShadow: 'none',
      },
    }),

    ...(inputsEndAdornment && {
      height: '100%',
      borderTopRightRadius: '1px',
      borderBottomRightRadius: '1px',
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
    }),
  }),
)

type ClickEvent = MouseEvent<HTMLButtonElement>

export type ButtonProps<C extends React.ElementType = 'button'> = Omit<
  StyledButtonProps<C>,
  'onClick'
> & {
  onClick?: (event: ClickEvent) => void
  /**
   * Adds confirmation dialog that will be opened after clicking this button.
   * onClick will be invoked only after confirming inside dialog
   */
  confirm?: ConfirmDialogProps
}

export const Button = forwardRef(
  <C extends React.ElementType>(
    { variant, onClick, confirm, ...rest }: ButtonProps<C>,
    ref: ForwardedRef<HTMLButtonElement>,
  ) => {
    const { openDialog, renderConfirmDialog } = useConfirmationDialog(onClick)

    return (
      <>
        <StyledButton
          {...rest}
          ref={ref}
          variant={variant ?? 'contained'}
          onClick={(event: ClickEvent) => {
            if (confirm) {
              openDialog(event)
            } else if (onClick) {
              onClick(event)
            }
          }}
        />

        {confirm && renderConfirmDialog(confirm)}
      </>
    )
  },
)
