import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export const IconCloseSmall = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 16 16">
    <path
      d="M4.64598 4.64604C4.69242 4.59948 4.7476 4.56253 4.80834 4.53733C4.86909 4.51212 4.93421 4.49915 4.99998 4.49915C5.06575 4.49915 5.13087 4.51212 5.19161 4.53733C5.25236 4.56253 5.30753 4.59948 5.35398 4.64604L7.99998 7.29304L10.646 4.64604C10.6925 4.59955 10.7477 4.56267 10.8084 4.53752C10.8691 4.51236 10.9342 4.49941 11 4.49941C11.0657 4.49941 11.1308 4.51236 11.1916 4.53752C11.2523 4.56267 11.3075 4.59955 11.354 4.64604C11.4005 4.69253 11.4373 4.74772 11.4625 4.80846C11.4877 4.86919 11.5006 4.9343 11.5006 5.00004C11.5006 5.06578 11.4877 5.13088 11.4625 5.19162C11.4373 5.25236 11.4005 5.30755 11.354 5.35404L8.70698 8.00004L11.354 10.646C11.4005 10.6925 11.4373 10.7477 11.4625 10.8085C11.4877 10.8692 11.5006 10.9343 11.5006 11C11.5006 11.0658 11.4877 11.1309 11.4625 11.1916C11.4373 11.2524 11.4005 11.3076 11.354 11.354C11.3075 11.4005 11.2523 11.4374 11.1916 11.4626C11.1308 11.4877 11.0657 11.5007 11 11.5007C10.9342 11.5007 10.8691 11.4877 10.8084 11.4626C10.7477 11.4374 10.6925 11.4005 10.646 11.354L7.99998 8.70704L5.35398 11.354C5.30749 11.4005 5.2523 11.4374 5.19156 11.4626C5.13082 11.4877 5.06572 11.5007 4.99998 11.5007C4.93423 11.5007 4.86913 11.4877 4.80839 11.4626C4.74766 11.4374 4.69247 11.4005 4.64598 11.354C4.59949 11.3076 4.56261 11.2524 4.53745 11.1916C4.5123 11.1309 4.49935 11.0658 4.49935 11C4.49935 10.9343 4.5123 10.8692 4.53745 10.8085C4.56261 10.7477 4.59949 10.6925 4.64598 10.646L7.29298 8.00004L4.64598 5.35404C4.59941 5.30759 4.56247 5.25242 4.53727 5.19167C4.51206 5.13093 4.49908 5.06581 4.49908 5.00004C4.49908 4.93427 4.51206 4.86915 4.53727 4.80841C4.56247 4.74766 4.59941 4.69248 4.64598 4.64604Z"
      fill="currentColor"
    />
  </SvgIcon>
)
