import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export const IconFileOutline = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 20 20">
    <path
      d="M6.875 8.75C6.70924 8.75 6.55027 8.81585 6.43306 8.93306C6.31585 9.05027 6.25 9.20924 6.25 9.375C6.25 9.54076 6.31585 9.69973 6.43306 9.81694C6.55027 9.93415 6.70924 10 6.875 10H13.125C13.2908 10 13.4497 9.93415 13.5669 9.81694C13.6842 9.69973 13.75 9.54076 13.75 9.375C13.75 9.20924 13.6842 9.05027 13.5669 8.93306C13.4497 8.81585 13.2908 8.75 13.125 8.75H6.875ZM6.25 11.875C6.25 11.7092 6.31585 11.5503 6.43306 11.4331C6.55027 11.3158 6.70924 11.25 6.875 11.25H13.125C13.2908 11.25 13.4497 11.3158 13.5669 11.4331C13.6842 11.5503 13.75 11.7092 13.75 11.875C13.75 12.0408 13.6842 12.1997 13.5669 12.3169C13.4497 12.4342 13.2908 12.5 13.125 12.5H6.875C6.70924 12.5 6.55027 12.4342 6.43306 12.3169C6.31585 12.1997 6.25 12.0408 6.25 11.875ZM6.25 14.375C6.25 14.2092 6.31585 14.0503 6.43306 13.9331C6.55027 13.8158 6.70924 13.75 6.875 13.75H9.375C9.54076 13.75 9.69973 13.8158 9.81694 13.9331C9.93415 14.0503 10 14.2092 10 14.375C10 14.5408 9.93415 14.6997 9.81694 14.8169C9.69973 14.9342 9.54076 15 9.375 15H6.875C6.70924 15 6.55027 14.9342 6.43306 14.8169C6.31585 14.6997 6.25 14.5408 6.25 14.375Z"
      fill="currentColor"
    />
    <path
      d="M11.875 0H5C4.33696 0 3.70107 0.263392 3.23223 0.732233C2.76339 1.20107 2.5 1.83696 2.5 2.5V17.5C2.5 18.163 2.76339 18.7989 3.23223 19.2678C3.70107 19.7366 4.33696 20 5 20H15C15.663 20 16.2989 19.7366 16.7678 19.2678C17.2366 18.7989 17.5 18.163 17.5 17.5V5.625L11.875 0ZM11.875 1.25V3.75C11.875 4.24728 12.0725 4.72419 12.4242 5.07583C12.7758 5.42746 13.2527 5.625 13.75 5.625H16.25V17.5C16.25 17.8315 16.1183 18.1495 15.8839 18.3839C15.6495 18.6183 15.3315 18.75 15 18.75H5C4.66848 18.75 4.35054 18.6183 4.11612 18.3839C3.8817 18.1495 3.75 17.8315 3.75 17.5V2.5C3.75 2.16848 3.8817 1.85054 4.11612 1.61612C4.35054 1.3817 4.66848 1.25 5 1.25H11.875Z"
      fill="currentColor"
    />
  </SvgIcon>
)
