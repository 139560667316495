import * as t from 'io-ts'
import { DateFromISOString } from 'io-ts-types'

const TUserStatus = t.union([
  t.literal('ACTIVE'),
  t.literal('INACTIVE'),
  t.literal('INVITED'),
])

export type UserStatus = t.TypeOf<typeof TUserStatus>

export const TUser = t.intersection([
  t.strict({
    userId: t.string,
    email: t.string,
    fullName: t.string,
    role: t.strict({
      code: t.string,
      name: t.string,
    }),
    status: TUserStatus,
  }),
  t.partial({
    avatar: t.string,
  }),
])

export const TUserIntegrationProvider = t.literal('GOOGLE')

export type UserIntegrationProvider = t.TypeOf<typeof TUserIntegrationProvider>

export const TUserIntegrationType = t.literal('CALENDAR')

export type UserIntegrationType = t.TypeOf<typeof TUserIntegrationType>

export const TUserIntegration = t.strict({
  email: t.string,
  type: TUserIntegrationType,
  provider: TUserIntegrationProvider,
  active: t.boolean,
})

export const TUserIntegrations = t.array(TUserIntegration)

export type UserIntegrations = t.TypeOf<typeof TUserIntegrations>

export const TUserDetails = t.intersection([
  TUser,
  t.strict({
    integrations: TUserIntegrations,
    createdContactsCount: t.number,
    createdJobsCount: t.number,
    createdJobRequestsCount: t.number,
    assignedJobsCount: t.number,
  }),
  t.partial({
    createdAt: DateFromISOString,
    registeredAt: DateFromISOString,
    statusUpdatedAt: DateFromISOString,
  }),
])

export type UserDetails = t.TypeOf<typeof TUserDetails>
