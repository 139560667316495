import { toast, ToastOptions } from 'react-toastify'
import { ToastContent, ToastContentProps } from 'ui/feedback'

export const showToast = (
  { type = 'success', ...params }: ToastContentProps,
  options?: ToastOptions,
) => {
  return toast[type](<ToastContent {...params} type={type} />, {
    ...options,
    containerId: (options && options.containerId) || 'system',
  })
}
