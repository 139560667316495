import {
  styled,
  Tab as MuiTab,
  tabClasses,
  TabProps as MuiTabProps,
} from '@mui/material'

const StyledTab = styled(props => (
  <MuiTab disableRipple {...props} />
))<MuiTabProps>(({ theme }) => ({
  ...theme.typography.body2,
  minWidth: 'auto',
  minHeight: 'auto',
  marginRight: theme.spacing(3),
  padding: theme.spacing(2, 0),
  textTransform: 'none',
  color: theme.palette.text.secondary,

  [`&.${tabClasses.selected}`]: {
    color: theme.palette.text.primary,
  },
}))

export const Tab = <C extends React.ElementType>(
  props: MuiTabProps<C, { component?: C }>,
) => {
  return <StyledTab {...props} />
}
