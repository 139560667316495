import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export const IconDocumentOutline = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 16 17">
    <g clipPath="url(#clip0_7402_66954)">
      <path
        d="M3.5 2.5C3.36739 2.5 3.24021 2.55268 3.14645 2.64645C3.05268 2.74021 3 2.86739 3 3V15C3 15.1326 3.05268 15.2598 3.14645 15.3536C3.24021 15.4473 3.36739 15.5 3.5 15.5H12.5C12.6326 15.5 12.7598 15.4473 12.8536 15.3536C12.9473 15.2598 13 15.1326 13 15V3C13 2.86739 12.9473 2.74021 12.8536 2.64645C12.7598 2.55268 12.6326 2.5 12.5 2.5H12C11.8674 2.5 11.7402 2.44732 11.6464 2.35355C11.5527 2.25979 11.5 2.13261 11.5 2C11.5 1.86739 11.5527 1.74021 11.6464 1.64645C11.7402 1.55268 11.8674 1.5 12 1.5H12.5C12.8978 1.5 13.2794 1.65804 13.5607 1.93934C13.842 2.22064 14 2.60218 14 3V15C14 15.3978 13.842 15.7794 13.5607 16.0607C13.2794 16.342 12.8978 16.5 12.5 16.5H3.5C3.10218 16.5 2.72064 16.342 2.43934 16.0607C2.15804 15.7794 2 15.3978 2 15V3C2 2.60218 2.15804 2.22064 2.43934 1.93934C2.72064 1.65804 3.10218 1.5 3.5 1.5H4C4.13261 1.5 4.25979 1.55268 4.35355 1.64645C4.44732 1.74021 4.5 1.86739 4.5 2C4.5 2.13261 4.44732 2.25979 4.35355 2.35355C4.25979 2.44732 4.13261 2.5 4 2.5H3.5Z"
        fill="currentColor"
      />
      <path
        d="M10 1C10 0.867392 9.94732 0.740215 9.85355 0.646447C9.75979 0.552678 9.63261 0.5 9.5 0.5H6.5C6.36739 0.5 6.24021 0.552678 6.14645 0.646447C6.05268 0.740215 6 0.867392 6 1C6 1.13261 5.94732 1.25979 5.85355 1.35355C5.75979 1.44732 5.63261 1.5 5.5 1.5C5.36739 1.5 5.24021 1.55268 5.14645 1.64645C5.05268 1.74021 5 1.86739 5 2V2.5C5 2.63261 5.05268 2.75979 5.14645 2.85355C5.24021 2.94732 5.36739 3 5.5 3H10.5C10.6326 3 10.7598 2.94732 10.8536 2.85355C10.9473 2.75979 11 2.63261 11 2.5V2C11 1.86739 10.9473 1.74021 10.8536 1.64645C10.7598 1.55268 10.6326 1.5 10.5 1.5C10.3674 1.5 10.2402 1.44732 10.1464 1.35355C10.0527 1.25979 10 1.13261 10 1Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_7402_66954">
        <rect
          width="16"
          height="16"
          fill="white"
          transform="translate(0 0.5)"
        />
      </clipPath>
    </defs>
  </SvgIcon>
)
