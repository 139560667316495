import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export const IconCreditCardOutline = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 16 17">
    <path
      d="M0 4.5C0 3.96957 0.210714 3.46086 0.585786 3.08579C0.960859 2.71071 1.46957 2.5 2 2.5H14C14.5304 2.5 15.0391 2.71071 15.4142 3.08579C15.7893 3.46086 16 3.96957 16 4.5V12.5C16 13.0304 15.7893 13.5391 15.4142 13.9142C15.0391 14.2893 14.5304 14.5 14 14.5H2C1.46957 14.5 0.960859 14.2893 0.585786 13.9142C0.210714 13.5391 0 13.0304 0 12.5V4.5ZM2 3.5C1.73478 3.5 1.48043 3.60536 1.29289 3.79289C1.10536 3.98043 1 4.23478 1 4.5V5.5H15V4.5C15 4.23478 14.8946 3.98043 14.7071 3.79289C14.5196 3.60536 14.2652 3.5 14 3.5H2ZM15 7.5H1V12.5C1 12.7652 1.10536 13.0196 1.29289 13.2071C1.48043 13.3946 1.73478 13.5 2 13.5H14C14.2652 13.5 14.5196 13.3946 14.7071 13.2071C14.8946 13.0196 15 12.7652 15 12.5V7.5Z"
      fill="currentColor"
    />
    <path
      d="M2 10.5C2 10.2348 2.10536 9.98043 2.29289 9.79289C2.48043 9.60536 2.73478 9.5 3 9.5H4C4.26522 9.5 4.51957 9.60536 4.70711 9.79289C4.89464 9.98043 5 10.2348 5 10.5V11.5C5 11.7652 4.89464 12.0196 4.70711 12.2071C4.51957 12.3946 4.26522 12.5 4 12.5H3C2.73478 12.5 2.48043 12.3946 2.29289 12.2071C2.10536 12.0196 2 11.7652 2 11.5V10.5Z"
      fill="currentColor"
    />
  </SvgIcon>
)
