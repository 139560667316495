import {
  IconButton as MuiIconButton,
  IconButtonProps as MuiIconButtonProps,
  styled,
} from '@mui/material'
import { ConfirmDialogProps, useConfirmationDialog } from 'lib/app-helpers'
import { ForwardedRef, forwardRef, MouseEvent } from 'react'

type ClickEvent = MouseEvent<HTMLButtonElement>

export type IconButtonProps<C extends React.ElementType> = MuiIconButtonProps<
  C,
  { component?: C }
> & {
  disablePadding?: boolean
  disableHover?: boolean
  sizePx?: number

  onClick?: (event: ClickEvent) => void
  confirm?: ConfirmDialogProps
}

const StyledButton = styled(MuiIconButton, {
  shouldForwardProp: prop =>
    prop !== 'disablePadding' && prop !== 'disableHover' && prop !== 'sizePx',
})(
  <C extends React.ElementType>({
    disablePadding,
    disableHover,
    sizePx = 32,
    theme,
  }: IconButtonProps<C>) => ({
    width: sizePx,
    height: sizePx,
    padding: theme.spacing(0.5),

    ...(disablePadding && {
      padding: 0,
      width: 'auto',
      height: 'auto',
    }),

    ...((disableHover || disablePadding) && {
      '&:hover': {
        background: 'none',
      },
    }),
  }),
)

export const IconButton = forwardRef(
  <C extends React.ElementType>(
    {
      onClick,
      confirm,
      disableHover,
      disablePadding,
      ...props
    }: IconButtonProps<C>,
    ref: ForwardedRef<HTMLButtonElement>,
  ) => {
    const { openDialog, renderConfirmDialog } = useConfirmationDialog(onClick)

    return (
      <>
        <StyledButton
          {...props}
          ref={ref}
          size="small"
          onClick={(event: ClickEvent) => {
            if (confirm) {
              openDialog(event)
            } else if (onClick) {
              onClick(event)
            }
          }}
          disableRipple={disableHover || disablePadding}
        />

        {confirm && renderConfirmDialog(confirm)}
      </>
    )
  },
)
