import { SvgIconProps } from '@mui/material'
import { CompanyStatus } from 'api'
import { ThemeColor } from 'app/mui-theme'
import {
  IconCheckCircleOutline,
  IconClockOutlineDashed,
  IconForbid,
  IconSnow,
} from 'assets/icons'

export const companyStatus: Record<
  CompanyStatus,
  {
    label: string
    hint: string
    Icon: (props: SvgIconProps<'svg', {}>) => JSX.Element
    color: ThemeColor
  }
> = {
  ACTIVE: {
    label: 'Active',
    hint: 'Company has active subscription',
    Icon: IconCheckCircleOutline,
    color: 'success.main',
  },
  INACTIVE: {
    label: 'Inactive',
    hint: 'Company is deactivated',
    Icon: IconForbid,
    color: 'error.main',
  },
  PENDING: {
    label: 'Pending',
    hint: 'Subscription has not been chosen after company’s registration',
    Icon: IconClockOutlineDashed,
    color: 'flushOrange',
  },
  FROZEN: {
    label: 'Frozen',
    hint: 'Subscription has not been renewed',
    Icon: IconSnow,
    color: 'greyBlue.light',
  },
}
