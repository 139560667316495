import {
  Breadcrumbs as MuiBreadcrumbs,
  breadcrumbsClasses,
  Typography,
} from '@mui/material'
import { IconArrowRightShort } from 'assets/icons'
import { Link } from 'ui/navigation/link'

export type BreadcrumbsLocations = Array<{
  name: string
  to?: string
}>

type Props = {
  locations: BreadcrumbsLocations
}

export const Breadcrumbs = (props: Props) => {
  return (
    <MuiBreadcrumbs
      sx={{
        [`& .${breadcrumbsClasses.li}`]: {
          lineHeight: 1,
        },

        [`& .${breadcrumbsClasses.separator}`]: {
          mx: '2px',
        },
      }}
      separator={
        <IconArrowRightShort
          sx={{ fontSize: '14px', color: 'greyBlue.light' }}
        />
      }
    >
      {props.locations.map(location =>
        location.to ? (
          <Link
            key={location.name}
            to={location.to}
            typography="caption"
            color="text.secondary"
          >
            {location.name}
          </Link>
        ) : (
          <Typography
            key={location.name}
            variant="caption"
            color="greyBlue.light"
          >
            {location.name}
          </Typography>
        ),
      )}
    </MuiBreadcrumbs>
  )
}
