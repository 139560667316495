import { authRoutes, paths, unauthRoutes } from 'app/routes'
import { NotFound } from 'components/global'
import { matchRoutes, Navigate, useLocation, useRoutes } from 'react-router-dom'

import { AuthLayout } from './auth-layout'

export const AuthApp = () => {
  const location = useLocation()

  const routes = useRoutes([
    ...authRoutes,
    {
      index: true,
      element: <Navigate to={paths.companies} />,
    },
    {
      path: '*',
      element: <NotFound />,
    },
  ])

  if (matchRoutes(unauthRoutes, location)) {
    return <Navigate to={paths.companies} replace />
  }

  return <AuthLayout>{routes}</AuthLayout>
}
