import { Stack } from '@mui/material'
import { paths } from 'app/routes'
import errorImage from 'assets/images/man-and-cat.png'
import { NoDataTemplate } from 'components/templates/no-data-template'
import { getRequestErrorMessage } from 'lib/request'
import { useTranslation } from 'react-i18next'
import { Button } from 'ui/inputs/button'
import { LinkButton } from 'ui/navigation/link'

export const ErrorTemplate = ({ error }: { error: unknown }) => {
  const { t } = useTranslation()
  const { title, message } = getRequestErrorMessage(error)

  return (
    <NoDataTemplate
      imgSrc={errorImage}
      title={title}
      imgWidth={260}
      subtitle={message}
      button={
        <Stack display="inline-flex" direction="row" spacing={2}>
          <LinkButton to={paths.companies} variant="outlined" color="greyBlue">
            {t('main.go_to_main_page')}
          </LinkButton>
          <Button onClick={() => window.location.reload()}>
            {t('main.refresh_this_page')}
          </Button>
        </Stack>
      }
    />
  )
}
