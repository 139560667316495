import { useTheme } from '@mui/material'
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export const IconSortArrows = ({
  sort,
  ...props
}: SvgIconProps & { sort?: 'asc' | 'desc' }) => {
  const theme = useTheme()

  return (
    <SvgIcon {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.9546 18L15.4546 14H8.45459L11.9546 18Z"
        fill={
          sort === 'asc' ? theme.palette.primary.dark : theme.palette.grey[400]
        }
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.9546 6L8.45459 10H15.4546L11.9546 6Z"
        fill={
          sort === 'desc' ? theme.palette.primary.dark : theme.palette.grey[400]
        }
      />
    </SvgIcon>
  )
}
