import { useAuthContext } from 'app/auth'
import { useCloseToastsOnRouteChange } from 'lib/toast'

import { AuthApp } from './auth'
import { UnauthApp } from './unauth'

export const Root = () => {
  const { user } = useAuthContext()

  useCloseToastsOnRouteChange()

  if (user.state === 'initial') {
    return null
  }

  if (user.state === 'unauthenticated') {
    return <UnauthApp />
  }

  return <AuthApp />
}
