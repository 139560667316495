import { PaymentCurrency, PeriodTypes } from 'api'
import { paymentCurrency, periodTypes } from 'lib/records'

type Params = {
  currency?: PaymentCurrency
  price?: number
  periodType: PeriodTypes
}

export const formatPaymentParameters = ({
  currency = 'EUR',
  price = 0,
  periodType = 'MONTH',
}: Params) => {
  return `${paymentCurrency[currency].symbol}${price}/${periodTypes[
    periodType
  ].toLowerCase()}`
}
