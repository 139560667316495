import { useBoolean } from 'lib/react-utils'
import { RefObject, useEffect } from 'react'

export const useIsVisible = (elementRef: RefObject<HTMLElement>) => {
  const isVisible = useBoolean(false)

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      // will trigger only the first visibility
      if (entry && entry.isIntersecting) {
        isVisible.setTrue()
        observer.disconnect()
      }
    })

    if (elementRef && elementRef.current) {
      observer.observe(elementRef.current)
    }

    return () => observer.disconnect()
  }, [elementRef, isVisible])

  return isVisible.isTrue
}
