import { Box, Paper, Stack, Typography } from '@mui/material'
import { IconPencilOutline } from 'assets/icons'
import { ErrorTemplateSmall } from 'components/templates'
import { useTranslation } from 'react-i18next'
import { CircularProgress } from 'ui/feedback'
import { Button } from 'ui/inputs/button'
import { LinkButton } from 'ui/navigation/link'

export type BlockProps = {
  title?: string
  children?: React.ReactNode
  isLoading?: boolean
  editRoute?: string
  description?: string
  error?: unknown
  onClickEdit?: () => void
}

export const SettingsBlock = (props: BlockProps) => {
  const { t } = useTranslation()

  const renderContent = () => {
    if (props.isLoading) {
      return (
        <CircularProgress
          size={32}
          sx={{ display: 'block', marginX: 'auto', mt: 3 }}
        />
      )
    }

    if (props.error) {
      return <ErrorTemplateSmall error={props.error} />
    }

    return props.children
  }

  return (
    <Paper component="section" sx={{ p: 3 }}>
      <Box
        display="flex"
        alignItems="flex-start"
        justifyContent="space-between"
      >
        <Stack spacing={2} mr={5}>
          {props.title && <Typography variant="h3">{props.title}</Typography>}

          {props.description && (
            <Typography variant="body2" color="text.secondary" maxWidth={580}>
              {props.description}
            </Typography>
          )}
        </Stack>

        {props.editRoute && (
          <LinkButton
            to={props.editRoute}
            startIcon={<IconPencilOutline sx={{ color: '#FFF' }} />}
            iconSize="12px"
          >
            {t('main.edit')}
          </LinkButton>
        )}

        {!props.editRoute && props.onClickEdit && (
          <Button
            onClick={props.onClickEdit}
            startIcon={<IconPencilOutline sx={{ color: '#FFF' }} />}
            iconSize="12px"
          >
            {t('main.edit')}
          </Button>
        )}
      </Box>

      {renderContent()}
    </Paper>
  )
}
