import { Box, Hidden, Typography } from '@mui/material'
import logoSrc from 'assets/images/logo.svg'
import { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'

type Props = {
  children: ReactNode
}

export const UnauthLayout = ({ children }: Props) => {
  const { t } = useTranslation()
  return (
    <Box
      display="flex"
      flexDirection="column"
      minHeight="100vh"
      overflow="hidden"
      paddingBottom={4}
    >
      <Box
        component="main"
        width="100%"
        maxWidth="1000px"
        padding={2}
        marginX="auto"
        flex="auto"
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <Hidden mdDown>
          <Box width="40%">
            <img
              src={logoSrc}
              width="100%"
              height="auto"
              alt="Axterior logotype"
            />
          </Box>
        </Hidden>

        <Box sx={{ width: { xs: '100%', md: '50%' } }}>
          <Box maxWidth="350px" width="100%" marginX="auto">
            {children}
          </Box>
        </Box>
      </Box>
      <Box component="footer" textAlign="center">
        <Typography variant="caption" color="textSecondary">
          {t('main.axterior_back_office')} {new Date().getFullYear()}
        </Typography>
      </Box>
    </Box>
  )
}
