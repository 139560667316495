import { useBoolean } from 'lib/react-utils'
import { useCallback, useRef } from 'react'
import {
  Dialog,
  DialogContent,
  DialogContentProps,
  DialogSize,
} from 'ui/feedback'

export type ConfirmDialogProps = Omit<
  DialogContentProps,
  'onConfirm' | 'onDeny'
> & {
  size?: DialogSize
  width?: string
}

export const useConfirmationDialog = <T,>(callback?: (params: T) => void) => {
  const visibility = useBoolean(false)
  const paramsRef = useRef<T | null>(null)

  const handleConfirm = useCallback(() => {
    if (callback && paramsRef.current !== null) {
      callback(paramsRef.current)
    }

    visibility.setFalse()
  }, [callback, visibility])

  const handleDeny = useCallback(() => {
    visibility.setFalse()
  }, [visibility])

  const handleOpen = useCallback(
    (params: T) => {
      paramsRef.current = params
      visibility.setTrue()
    },
    [visibility],
  )

  const renderConfirmDialog = useCallback(
    ({ size, width, ...contentProps }: ConfirmDialogProps) => (
      <Dialog
        open={visibility.isTrue}
        onClose={handleDeny}
        size={size}
        width={width}
      >
        <DialogContent
          {...contentProps}
          onDeny={handleDeny}
          onConfirm={handleConfirm}
        />
      </Dialog>
    ),
    [handleConfirm, handleDeny, visibility.isTrue],
  )

  return {
    openDialog: handleOpen,
    renderConfirmDialog,
  }
}
