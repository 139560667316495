import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export const IconCalendarDaysOutline = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 16 17">
    <g clipPath="url(#clip0_8171_77523)">
      <path
        d="M3.5 0.5C3.63261 0.5 3.75979 0.552678 3.85355 0.646447C3.94732 0.740215 4 0.867392 4 1V1.5H12V1C12 0.867392 12.0527 0.740215 12.1464 0.646447C12.2402 0.552678 12.3674 0.5 12.5 0.5C12.6326 0.5 12.7598 0.552678 12.8536 0.646447C12.9473 0.740215 13 0.867392 13 1V1.5H14C14.5304 1.5 15.0391 1.71071 15.4142 2.08579C15.7893 2.46086 16 2.96957 16 3.5V14.5C16 15.0304 15.7893 15.5391 15.4142 15.9142C15.0391 16.2893 14.5304 16.5 14 16.5H2C1.46957 16.5 0.960859 16.2893 0.585786 15.9142C0.210714 15.5391 0 15.0304 0 14.5V3.5C0 2.96957 0.210714 2.46086 0.585786 2.08579C0.960859 1.71071 1.46957 1.5 2 1.5H3V1C3 0.867392 3.05268 0.740215 3.14645 0.646447C3.24021 0.552678 3.36739 0.5 3.5 0.5V0.5ZM2 2.5C1.73478 2.5 1.48043 2.60536 1.29289 2.79289C1.10536 2.98043 1 3.23478 1 3.5V4.5H15V3.5C15 3.23478 14.8946 2.98043 14.7071 2.79289C14.5196 2.60536 14.2652 2.5 14 2.5H2ZM15 5.5H1V14.5C1 14.7652 1.10536 15.0196 1.29289 15.2071C1.48043 15.3946 1.73478 15.5 2 15.5H14C14.2652 15.5 14.5196 15.3946 14.7071 15.2071C14.8946 15.0196 15 14.7652 15 14.5V5.5Z"
        fill="currentColor"
      />
      <path
        d="M11 8C11 7.86739 11.0527 7.74021 11.1464 7.64645C11.2402 7.55268 11.3674 7.5 11.5 7.5H12.5C12.6326 7.5 12.7598 7.55268 12.8536 7.64645C12.9473 7.74021 13 7.86739 13 8V9C13 9.13261 12.9473 9.25979 12.8536 9.35355C12.7598 9.44732 12.6326 9.5 12.5 9.5H11.5C11.3674 9.5 11.2402 9.44732 11.1464 9.35355C11.0527 9.25979 11 9.13261 11 9V8ZM8 8C8 7.86739 8.05268 7.74021 8.14645 7.64645C8.24021 7.55268 8.36739 7.5 8.5 7.5H9.5C9.63261 7.5 9.75979 7.55268 9.85355 7.64645C9.94732 7.74021 10 7.86739 10 8V9C10 9.13261 9.94732 9.25979 9.85355 9.35355C9.75979 9.44732 9.63261 9.5 9.5 9.5H8.5C8.36739 9.5 8.24021 9.44732 8.14645 9.35355C8.05268 9.25979 8 9.13261 8 9V8ZM6 11C6 10.8674 6.05268 10.7402 6.14645 10.6464C6.24021 10.5527 6.36739 10.5 6.5 10.5H7.5C7.63261 10.5 7.75979 10.5527 7.85355 10.6464C7.94732 10.7402 8 10.8674 8 11V12C8 12.1326 7.94732 12.2598 7.85355 12.3536C7.75979 12.4473 7.63261 12.5 7.5 12.5H6.5C6.36739 12.5 6.24021 12.4473 6.14645 12.3536C6.05268 12.2598 6 12.1326 6 12V11ZM3 11C3 10.8674 3.05268 10.7402 3.14645 10.6464C3.24021 10.5527 3.36739 10.5 3.5 10.5H4.5C4.63261 10.5 4.75979 10.5527 4.85355 10.6464C4.94732 10.7402 5 10.8674 5 11V12C5 12.1326 4.94732 12.2598 4.85355 12.3536C4.75979 12.4473 4.63261 12.5 4.5 12.5H3.5C3.36739 12.5 3.24021 12.4473 3.14645 12.3536C3.05268 12.2598 3 12.1326 3 12V11Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_8171_77523">
        <rect
          width="16"
          height="16"
          fill="white"
          transform="translate(0 0.5)"
        />
      </clipPath>
    </defs>
  </SvgIcon>
)
