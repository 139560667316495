import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export const IconMailOutline = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <path
      d="M0 6C0 5.20435 0.316071 4.44129 0.87868 3.87868C1.44129 3.31607 2.20435 3 3 3H21C21.7956 3 22.5587 3.31607 23.1213 3.87868C23.6839 4.44129 24 5.20435 24 6V18C24 18.7956 23.6839 19.5587 23.1213 20.1213C22.5587 20.6839 21.7956 21 21 21H3C2.20435 21 1.44129 20.6839 0.87868 20.1213C0.316071 19.5587 0 18.7956 0 18V6ZM3 4.5C2.60218 4.5 2.22064 4.65804 1.93934 4.93934C1.65804 5.22064 1.5 5.60218 1.5 6V6.3255L12 12.6255L22.5 6.3255V6C22.5 5.60218 22.342 5.22064 22.0607 4.93934C21.7794 4.65804 21.3978 4.5 21 4.5H3ZM22.5 8.0745L15.438 12.312L22.5 16.6575V8.0745ZM22.449 18.3885L13.989 13.182L12 14.3745L10.011 13.182L1.551 18.387C1.63624 18.7062 1.82447 18.9883 2.08648 19.1896C2.34849 19.3908 2.66962 19.5 3 19.5H21C21.3302 19.5 21.6512 19.3911 21.9131 19.1902C22.1751 18.9892 22.3635 18.7074 22.449 18.3885ZM1.5 16.6575L8.562 12.312L1.5 8.0745V16.6575Z"
      fill="currentColor"
    />
  </SvgIcon>
)
