import { env } from 'app/env'
import { pipe } from 'fp-ts/function'
import { decodeJson, del, post } from 'lib/request'

import { TAuthResponse } from './token.codecs'

export const refreshToken = async ({
  countryCode,
}: {
  countryCode?: string
}) => {
  return pipe(
    await post('auth/tokens/refresh', {
      body: {
        clientId: env.REACT_APP_CLIENT_ID,
        countryCode,
      },
    }),
    decodeJson(TAuthResponse),
  )
}

export const login = async ({
  email,
  password,
  countryCode,
}: {
  email: string
  password: string
  countryCode?: string
}) => {
  return pipe(
    await post('auth/tokens/credentials', {
      body: {
        clientId: env.REACT_APP_CLIENT_ID,
        email,
        password,
        countryCode,
      },
    }),
    decodeJson(TAuthResponse),
  )
}

export const logout = async () => {
  return await del('auth/tokens/me', {})
}
