import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { toast } from 'react-toastify'

type LocationState = { saveToasts?: boolean } | null

export const useCloseToastsOnRouteChange = () => {
  const location = useLocation()

  const state = location.state as LocationState

  // Remove all system toasts on route change
  // If toasts should remain, pass saveToasts as true via location state
  useEffect(() => {
    if (!(state && state.saveToasts)) {
      toast.dismiss()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])
}
