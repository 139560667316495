import { Box, List, ListItem, Stack, Typography } from '@mui/material'
import { IconCheck } from 'assets/icons'
import { getValidationErrors } from 'lib/form-utils'
import { DeepMap, FieldError, FieldValues } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

export const ValidationErrors = (props: {
  errors: DeepMap<FieldValues, FieldError>
  labels: { [key: string]: string }
  /**
   * Fields that are required by default
   * and needs be to visible even in case they pass the validation
   */
  required?: Array<string>
}) => {
  const { t } = useTranslation()
  const validationErrors = getValidationErrors(props.errors)

  const allRequired = props.required
    ? [
        ...props.required,
        ...validationErrors.required.filter(
          field => !props.required?.includes(field) ?? true,
        ),
      ]
    : validationErrors.required

  return (
    <Stack spacing={1}>
      {validationErrors.required.length > 0 && (
        <Box>
          <Typography variant="body2" mb={1}>
            {t('main.following_fields_required')}:
          </Typography>
          <List disablePadding>
            {allRequired.map(field => {
              const label = props.labels[field]
              return label ? (
                <ListItem key={field} sx={{ padding: '2px 4px' }}>
                  <IconCheck
                    sx={{
                      fontSize: '12px',
                      color: validationErrors.required.includes(field)
                        ? '#B2B6BE'
                        : 'primary.main',
                      mr: 1,
                    }}
                  />
                  {label}
                </ListItem>
              ) : null
            })}
          </List>
        </Box>
      )}

      {validationErrors.patterns.length > 0 && (
        <Box>
          <Typography variant="body2" mb={1}>
            {t('main.some_validations_missing')}:
          </Typography>
          {validationErrors.patterns.map(pattern => {
            const label = props.labels[pattern.field]
            return label ? (
              <Typography key={pattern.field} variant="body2" sx={{ mb: 1 }}>
                <b>{label}</b>: {pattern.message}
              </Typography>
            ) : null
          })}
        </Box>
      )}
    </Stack>
  )
}
