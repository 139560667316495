import { CSSObject } from '@mui/material'

/**
 * Nifty trick to create bold text transition without layout shift.
 * IMPORTANT: pass text value as data-text attribute
 */
export const boldOnHover: CSSObject = {
  display: 'inline-flex',
  flexDirection: 'column',

  ':hover': {
    fontWeight: 500,
  },

  '::after': {
    content: 'attr(data-text)',
    height: 0,
    visibility: 'hidden',
    overflow: 'hidden',
    fontWeight: 500,
  },
}

export const noWrap: CSSObject = {
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
}
