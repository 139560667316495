import * as t from 'io-ts'

export const TEmail = t.strict({
  emailId: t.string,
  title: t.string,
  active: t.boolean,
  recipients: t.array(t.string),
})

export type Email = t.TypeOf<typeof TEmail>

export const TEmailsList = t.array(TEmail)
