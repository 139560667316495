import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export const IconWarningOutline = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 16 16">
    <circle cx="8" cy="8" r="7.5" stroke="currentColor" fill="none" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 4C8.27614 4 8.5 4.22386 8.5 4.5V9.5C8.5 9.77614 8.27614 10 8 10C7.72386 10 7.5 9.77614 7.5 9.5V4.5C7.5 4.22386 7.72386 4 8 4Z"
      fill="currentColor"
      stroke="currentColor"
      strokeWidth="0.5"
      strokeLinecap="round"
    />
    <path
      d="M8.5 11.3C8.5 11.5762 8.27614 11.8 8 11.8C7.72386 11.8 7.5 11.5762 7.5 11.3C7.5 11.0239 7.72386 10.8 8 10.8C8.27614 10.8 8.5 11.0239 8.5 11.3Z"
      fill="currentColor"
      stroke="currentColor"
      strokeWidth="0.5"
    />
  </SvgIcon>
)
