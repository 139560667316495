import { DeepMap, FieldError, FieldValues } from 'react-hook-form'

export type ValidationErrors = {
  required: Array<string>
  patterns: Array<{ field: string; message: string }>
}

/**
 * Recursive function that transforms react-hook-form errors (DeepMap object)
 * into array of required fields and array of other errors with custom messages
 */
export const getValidationErrors = (
  errors: DeepMap<FieldValues, FieldError>,
  fieldName?: string,
): ValidationErrors => {
  const result: ValidationErrors = {
    required: [],
    patterns: [],
  }

  Object.entries(errors).map(([field, error]) => {
    const name = fieldName ?? field

    if (!error) return

    if ('type' in error) {
      // Deepest FieldError object is found
      if (error.type === 'required') {
        // Group required fields in special array
        result.required.push(name)
      } else {
        // Others are grouped as custom patterns with messages
        result.patterns.push({
          field: name,
          message: error.message,
        })
      }
    } else {
      let deepObj

      if (Array.isArray(error)) {
        // When using useFieldArray -> FieldError is the array of errors
        // In this case retrieve first error as an example
        deepObj = error.find(Boolean)
      } else if (typeof error === 'object') {
        // When FieldError contains other nested errors
        deepObj = error
      } else {
        return
      }

      // Recursively get nested errors
      const deepErrors = getValidationErrors(deepObj, name)

      // Combine all nested results with main one
      result.required.push(...deepErrors.required)
      result.patterns.push(...deepErrors.patterns)
    }
  })

  return result
}
