import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export const IconPencilOutline = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 16 16">
    <g clipPath="url(#clip0_4829_10839)">
      <path
        d="M12.1461 0.145917C12.1925 0.099354 12.2477 0.0624111 12.3084 0.0372047C12.3692 0.0119982 12.4343 -0.000976563 12.5001 -0.000976562C12.5658 -0.000976562 12.6309 0.0119982 12.6917 0.0372047C12.7524 0.0624111 12.8076 0.099354 12.8541 0.145917L15.8541 3.14592C15.9006 3.19236 15.9376 3.24754 15.9628 3.30828C15.988 3.36903 16.0009 3.43415 16.0009 3.49992C16.0009 3.56568 15.988 3.63081 15.9628 3.69155C15.9376 3.7523 15.9006 3.80747 15.8541 3.85392L5.85405 13.8539C5.80607 13.9016 5.74891 13.939 5.68605 13.9639L0.68605 15.9639C0.595186 16.0003 0.495646 16.0092 0.39977 15.9895C0.303893 15.9699 0.215897 15.9225 0.146691 15.8533C0.0774845 15.7841 0.030111 15.6961 0.0104435 15.6002C-0.00922396 15.5043 -0.000320453 15.4048 0.0360502 15.3139L2.03605 10.3139C2.06098 10.2511 2.0984 10.1939 2.14605 10.1459L12.1461 0.145917ZM11.2071 2.49992L13.5001 4.79292L14.7931 3.49992L12.5001 1.20692L11.2071 2.49992ZM12.7931 5.49992L10.5001 3.20692L4.00005 9.70692V9.99992H4.50005C4.63266 9.99992 4.75984 10.0526 4.8536 10.1464C4.94737 10.2401 5.00005 10.3673 5.00005 10.4999V10.9999H5.50005C5.63266 10.9999 5.75984 11.0526 5.8536 11.1464C5.94737 11.2401 6.00005 11.3673 6.00005 11.4999V11.9999H6.29305L12.7931 5.49992ZM3.03205 10.6749L2.92605 10.7809L1.39805 14.6019L5.21905 13.0739L5.32505 12.9679C5.22967 12.9323 5.14744 12.8684 5.08937 12.7847C5.03129 12.7011 5.00013 12.6017 5.00005 12.4999V11.9999H4.50005C4.36744 11.9999 4.24026 11.9472 4.1465 11.8535C4.05273 11.7597 4.00005 11.6325 4.00005 11.4999V10.9999H3.50005C3.39823 10.9998 3.29886 10.9687 3.21523 10.9106C3.1316 10.8525 3.06769 10.7703 3.03205 10.6749Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_4829_10839">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </SvgIcon>
)
