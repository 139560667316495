import { getOrElseW, mapLeft } from 'fp-ts/Either'
import { flow } from 'fp-ts/function'
import { literal, strict, string, union } from 'io-ts'
import { formatValidationErrors } from 'io-ts-reporters'

const throwHumanReadableErrors = (errors: Array<string>): never => {
  const error = new Error(`\n${errors.map(error => '❗' + error).join('\n')}`)
  error.name = 'EnvironmentVariablesDecodeError'
  throw error
}

const getEnvVariables = flow(
  strict,
  Codec => Codec.decode(process.env),
  mapLeft(formatValidationErrors),
  getOrElseW(throwHumanReadableErrors),
)

export const env = getEnvVariables({
  REACT_APP_MSW: union([literal('on'), literal('off'), literal('quiet')]),
  REACT_APP_CLIENT_ID: string,
  REACT_APP_GOOGLE_API_KEY: string,
  REACT_APP_DEBUG_I18N: union([literal('on'), literal('off')]),
  REACT_APP_ENV: union([literal('development'), literal('production')]),
})
