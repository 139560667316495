import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export const IconCheckCircleOutline = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 16 16">
    <circle cx="8" cy="8" r="7.5" stroke="currentColor" fill="none" />
    <path
      d="M5.27339 7.19838C4.9728 6.91339 4.49809 6.92603 4.2131 7.22661C3.92811 7.5272 3.94075 8.00191 4.24133 8.2869L5.27339 7.19838ZM7.61045 10.4477L7.09443 10.992C7.23912 11.1292 7.43248 11.2031 7.63179 11.1974C7.8311 11.1918 8.01995 11.107 8.1566 10.9618L7.61045 10.4477ZM12.1989 6.6668C12.4828 6.36517 12.4684 5.89051 12.1668 5.60662C11.8652 5.32274 11.3905 5.33712 11.1066 5.63875L12.1989 6.6668ZM4.24133 8.2869L7.09443 10.992L8.12648 9.90348L5.27339 7.19838L4.24133 8.2869ZM8.1566 10.9618L12.1989 6.6668L11.1066 5.63875L7.0643 9.93371L8.1566 10.9618Z"
      fill="currentColor"
    />
  </SvgIcon>
)
