import { z } from 'zod'

const errorMap: z.ZodErrorMap = (issue, ctx) => {
  if (
    (issue.code === z.ZodIssueCode.invalid_type &&
      issue.received === z.ZodParsedType.null) ||
    (issue.code === z.ZodIssueCode.too_small && issue.minimum === 1)
  ) {
    return { message: 'Please fill in this field' }
  }

  return { message: ctx.defaultError }
}

z.setErrorMap(errorMap)
