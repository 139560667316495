/**
 * @fileOverview
 * This file provides a utility function for creating nullable io-ts codecs.
 * A nullable codec is a type that represents either the specified codec or null.
 * It is useful for scenarios where a value can be optional.
 */
import * as t from 'io-ts'

/**
 * Creates a nullable io-ts codec by combining the specified codec with the null type.
 * @param {t.Mixed} codec - The io-ts codec to make nullable.
 * @returns {t.Type<t.TypeOf<typeof codec> | null>} A new io-ts codec that allows the specified type or null.
 * @example
 * const TCodec = t.strict({
 *   field: t.string,
 * });
 *
 * const TNullableCodec = nullableCodec(TCodec);
 */
export const nullableCodec = <Codec extends t.Mixed>(
  codec: Codec,
): t.Type<t.TypeOf<Codec> | null> => t.union([codec, t.null])
