import { Typography } from '@mui/material'
import { paths } from 'app/routes'
import { FallbackProps } from 'react-error-boundary'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Dialog, DialogContent } from 'ui/feedback'

export const RuntimeErrorDialog = (props: FallbackProps) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  return (
    <Dialog open>
      <DialogContent
        title={t('main.something_went_wrong')}
        denyText={t('main.refresh_this_page')}
        confirmText={t('main.go_to_main_page')}
        onDeny={() => {
          navigate(0)
        }}
        onConfirm={() => {
          props.resetErrorBoundary()
          navigate(paths.companies)
        }}
      >
        <>
          <Typography variant="body2" color="text.secondary">
            {t('main.something_went_wrong_message')} <br />
            {t('main.please_report_developer_team')}
          </Typography>

          {props.error.message && (
            <Typography
              component="pre"
              variant="body2"
              whiteSpace="pre-wrap"
              color="error"
              maxHeight={500}
              overflow="auto"
              mt={2}
            >
              {props.error.message}
            </Typography>
          )}
        </>
      </DialogContent>
    </Dialog>
  )
}
