import { paths } from 'app/routes'
import { StrictMode } from 'react'
import { matchPath, useLocation } from 'react-router-dom'

type Props = Readonly<{
  children: JSX.Element
}>

// https://github.com/atlassian/react-beautiful-dnd/issues/2350
// react-beautiful-dnd doesn't work in React 18 StrictMode
// Tried using @hello-pangea/dnd fork, but it works with some delay, unfortunately
// So the final solution is to disable strict mode on pages with DnD
export const StrictModeConditional = ({ children }: Props) => {
  const location = useLocation()

  const matchPaths = (paths: Array<string>) => {
    return paths.some(path =>
      matchPath({ path, end: false }, location.pathname),
    )
  }

  if (matchPaths([paths.createSubscription, paths.editSubscription])) {
    return children
  }

  return <StrictMode>{children}</StrictMode>
}
