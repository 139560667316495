import { buildModuleCacheKey } from 'lib/react-query-utils'

import {
  GetChargeAttemptsListInput,
  GetCompaniesListInput,
  GetCompanyChargesListInput,
  GetCompanyDetailsInput,
  GetCompanyUsersListInput,
} from './companies'
import { GetEmailsListInput } from './emails'
import {
  GetPromotionDetailsInput,
  GetPromotionsInput,
  GetRegisteredCompaniesByPromotionInput,
} from './promotions'
import {
  GetCompanySubscriptionInput,
  GetSubscriptionDetailsInput,
  GetSubscriptionsListInput,
} from './subscriptions'

/**
 * Same list can be displayed with the standard page pagination in one place (useQuery)
 * and with the infinite scroll pagination in another place (useInfiniteQuery)
 * We want to keep appropriate query cache entries for them separately
 * So we add { infinite: true } to the query key used with useInfiniteQuery
 */
export type InfiniteQueryKey = {
  infinite?: true
}

/**
 * `qk` stands for "query keys"
 */
export const qk = buildModuleCacheKey({
  auth: {
    token: {
      refresh: null,
    },
  },

  companies: {
    list: (input: GetCompaniesListInput & InfiniteQueryKey) => [input],
    details: (input: GetCompanyDetailsInput) => [input],
    subscriptionDetails: (input: GetCompanySubscriptionInput) => [input],
    usersList: (input: GetCompanyUsersListInput) => [input],
    userDetails: (input: { companyId: string; userId: string }) => [input],
    charges: (input: GetCompanyChargesListInput) => [input],
    chargeAttempts: (input: GetChargeAttemptsListInput) => [input],
  },

  external: {
    dataByIP: null,
  },

  subscriptions: {
    list: (input: GetSubscriptionsListInput) => [input],
    details: (input: GetSubscriptionDetailsInput) => [input],
    default: null,
    defaultList: null,
    features: null,
  },

  emails: {
    list: (input: GetEmailsListInput) => [input],
  },

  promotions: {
    list: (input: GetPromotionsInput) => [input],
    details: (input: GetPromotionDetailsInput) => [input],
    companiesList: (input: GetRegisteredCompaniesByPromotionInput) => [input],
  },
})
