import { useQuery } from '@tanstack/react-query'
import { qk, refreshToken } from 'api'
import { getDataByIP } from 'api/companies/external'
import constate from 'constate'
import { isRight } from 'fp-ts/lib/Either'
import { useCallback, useRef, useState } from 'react'

import {
  AccessTokenPayload,
  decodeAccessToken,
  setGlobalAccessToken,
} from './access-token'
import { useLogoutAcrossTabs } from './use-logout-across-tabs'

type NotAuthUser = {
  state: 'initial' | 'unauthenticated'
}

type AuthUser = {
  state: 'authenticated'
  accessToken: string
} & AccessTokenPayload

type User = NotAuthUser | AuthUser

const useAuth = () => {
  const [user, setUser] = useState<User>({ state: 'initial' })
  const refreshTimeoutRef = useRef<number | null>(null)
  const $dataByIP = useQuery(qk.external.dataByIP.toKey(), getDataByIP)
  const [refetchInterval, setRefetchInterval] = useState<number | undefined>()

  const { refetch: refetchRefreshToken } = useQuery(
    qk.auth.token.refresh.toKey(),
    () => refreshToken({ countryCode: $dataByIP.data?.country }),
    {
      staleTime: Number.POSITIVE_INFINITY,
      enabled: !$dataByIP.isLoading, // Enable the query for auth pages
      refetchInterval,
      onSuccess: ({ accessToken }) => {
        authorize(accessToken)
      },
      onError: () => {
        unauthorize()
      },
    },
  )

  const authorize = (accessToken: string) => {
    const payload = decodeAccessToken(accessToken)

    if (isRight(payload)) {
      setGlobalAccessToken(accessToken)

      setUser({
        state: 'authenticated',
        accessToken,
        ...payload.right,
      })

      // !We have -30 to send request before token will be expired
      const expiresIn = (payload.right.exp - 30) * 1000 - Date.now()
      setRefetchInterval(expiresIn)
    } else {
      setUser({ state: 'unauthenticated' })
    }
  }

  const unauthorize = useLogoutAcrossTabs(
    useCallback(() => {
      setGlobalAccessToken(null)
      setRefetchInterval(-1)
      setUser({ state: 'unauthenticated' })
      if (refreshTimeoutRef.current !== null) {
        window.clearTimeout(refreshTimeoutRef.current)
      }
    }, []),
  )

  return {
    user,
    authorize,
    refetchRefreshToken,
    unauthorize,
  } as const
}

export const [AuthProvider, useAuthContext] = constate(useAuth)

/**
 * Use in components that are definitely protected from unauthorized users.
 */
export const useAuthUser = () => {
  const { user } = useAuthContext()
  return user as AuthUser
}
