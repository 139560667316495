import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export const IconEyeOpenOutline = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 17 15">
    <path
      d="M8.53931 4.00977C11.3945 4.00977 15.0786 7.27942 15.0786 7.27942C15.0786 7.27942 11.3945 10.5491 8.53931 10.5491C5.68412 10.5491 2 7.27942 2 7.27942C2 7.27942 5.68412 4.00977 8.53931 4.00977Z"
      fill="none"
      stroke="currentColor"
      strokeWidth="1.4"
    />
    <circle cx="8.53916" cy="7.27841" r="2.45224" fill="currentColor" />
  </SvgIcon>
)
