import { globalAccessToken } from 'app/auth'
import { isRight } from 'fp-ts/lib/Either'
import { identity, pipe } from 'fp-ts/lib/function'
import { map } from 'fp-ts/lib/ReadonlyRecord'
import { strict, string } from 'io-ts'
import { formatValidationErrors } from 'io-ts-reporters'
import { generatePath, ParamParseKey } from 'react-router-dom'

import { concatQueryParams } from './concat-query-params'
import { createBody } from './create-body'
import { isRequestError, requestError } from './error-types'
import { getValidationMessage } from './get-validation-message'

export type RestMethod = 'DELETE' | 'GET' | 'OPTIONS' | 'PATCH' | 'POST' | 'PUT'

type RequestBody = Record<string, any>

type RequestQuery = URLSearchParams

type RequestHeaders = Headers

type Input<TPath extends string> = {
  params?: Record<ParamParseKey<TPath>, string>
  body?: RequestBody
  query?: RequestQuery
  headers?: RequestHeaders
  init?: RequestInit
  type?: 'file' | 'json'
  externalUrl?: boolean
}

type CreateRequestInput = (requestInit: RequestInit) => RequestInit

type RequestFunction = (
  method: RestMethod,
) => <TPath extends string>(
  httpUrl: TPath,
  input: Input<TPath>,
) => Promise<Response>

const TClientError = strict({
  code: string,
  message: string,
})

const createRequest = (
  intercept: CreateRequestInput = identity,
): RequestFunction => {
  return (method: RestMethod) => {
    return async <TPath extends string>(
      httpUrl: TPath,
      input: Input<TPath>,
    ) => {
      const headers = new Headers(input.headers)

      const inputType = input.type ?? 'json'

      if (inputType === 'json') {
        headers.set('Content-Type', 'application/json')
      }

      const requestInit = intercept({
        body: createBody(input.body, inputType),
        headers,
        method,
        ...input.init,
      })

      try {
        const params = pipe(
          input.params ?? {},
          map(encodeURIComponent) as any,
        ) as any

        const response = await window.fetch(
          concatQueryParams(
            generatePath(input.externalUrl ? httpUrl : `/${httpUrl}`, params),
            input.query,
          ),
          requestInit,
        )

        if (response.status >= 500) {
          throw requestError({
            response,
            type: 'server',
          })
        }

        if (response.status >= 400) {
          try {
            const error = await response.json()
            const decodedError = TClientError.decode(error)

            if (isRight(decodedError)) {
              throw requestError({
                type: 'client',
                response,
                code: decodedError.right.code,
                message: decodedError.right.message,
              })
            }

            const errors = formatValidationErrors(decodedError.left)
            throw requestError({
              json: error,
              message: getValidationMessage(errors),
              type: 'decode_body',
            })
          } catch (error) {
            if (isRequestError(error)) {
              throw error
            }

            if (error instanceof Error) {
              throw requestError({
                error,
                response,
                type: 'parse_json',
              })
            }

            // This shouldn't happen
            throw error
          }
        }

        return response
      } catch (error) {
        if (isRequestError(error)) {
          throw error
        }

        if (error instanceof TypeError) {
          throw requestError({
            error,
            type: 'network',
          })
        }

        // This shouldn't happen
        throw error
      }
    }
  }
}

const createRequestMethods = (request: RequestFunction) => {
  return {
    del: request('DELETE'),
    get: request('GET'),
    options: request('OPTIONS'),
    patch: request('PATCH'),
    post: request('POST'),
    put: request('PUT'),
  }
}

export const { get, post, put, del, patch, options } = createRequestMethods(
  createRequest(requestInit => {
    const headers = new Headers(requestInit.headers)

    if (globalAccessToken !== null) {
      headers.set('Authorization', `Bearer ${globalAccessToken}`)
    }

    return {
      ...requestInit,
      headers,
    }
  }),
)
