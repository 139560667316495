import { NumberParam } from 'lib/query-params'
import { useCallback, useMemo } from 'react'
import { Pagination } from 'ui/data'
import useLocalStorageState from 'use-local-storage-state'
import { useQueryParam } from 'use-query-params'

export const usePagination = (sizeLocalStorageKey: string): Pagination => {
  const [index, setIndex] = useQueryParam('page', NumberParam)
  const [size, setSize] = useLocalStorageState(sizeLocalStorageKey, {
    defaultValue: 25,
  })

  const handleChangePage = useCallback(
    (index: number) => {
      window.scrollTo(0, 0)
      setIndex(index)
    },
    [setIndex],
  )

  const handleChangeSize = useCallback(
    (size: number) => {
      handleChangePage(0)
      setSize(size)
    },
    [handleChangePage, setSize],
  )

  return useMemo(() => {
    return {
      page: index,
      pageSize: size,
      onChangePage: handleChangePage,
      onChangePageSize: handleChangeSize,
    }
  }, [index, size, handleChangePage, handleChangeSize])
}
