import { Box, BoxProps, Tooltip, TooltipProps } from '@mui/material'
import { useBoolean } from 'lib/react-utils'
import { useEffect } from 'react'
import CopyToClipboard from 'react-copy-to-clipboard'

type Props = {
  children: React.ReactNode
  text: string
  placement?: TooltipProps['placement']
  containerProps?: BoxProps
}

export const CopyText = ({
  children,
  placement,
  text,
  containerProps,
}: Props) => {
  const $copied = useBoolean(false)

  useEffect(() => {
    if ($copied.isTrue) {
      setTimeout(() => {
        $copied.setFalse()
      }, 3000)
    }
  }, [$copied])

  return (
    <CopyToClipboard text={text} onCopy={$copied.setTrue}>
      <Tooltip
        arrow
        title={$copied.isTrue ? 'Copied' : 'Click to copy'}
        placement={placement}
        TransitionProps={{
          onExited: $copied.setFalse,
        }}
      >
        <Box
          {...containerProps}
          sx={{ cursor: 'pointer', ...containerProps?.sx }}
        >
          {children}
        </Box>
      </Tooltip>
    </CopyToClipboard>
  )
}
