import { Box, Paper, Typography } from '@mui/material'
import computerImage from 'assets/images/computer.png'

type Props = {
  title: string
  imgSrc?: string
  imgWidth?: number
  subtitle?: string
  button?: React.ReactNode
}

export const NoDataTemplate = ({
  title,
  imgSrc = computerImage,
  imgWidth = 280,
  subtitle,
  button,
}: Props) => {
  return (
    <Paper
      sx={{
        mx: 'auto',
        pt: 5,
        px: 7.5,
        pb: 6,
        width: 480,
        textAlign: 'center',
      }}
    >
      <Box mb={2.5}>
        <Box
          component="img"
          src={imgSrc}
          alt={title}
          width={imgWidth}
          maxWidth="100%"
        />
      </Box>

      <Typography variant="h1">{title}</Typography>

      {subtitle && (
        <Typography variant="body2" color="text.secondary" mt={1}>
          {subtitle}
        </Typography>
      )}

      {button && <Box mt={2.5}>{button}</Box>}
    </Paper>
  )
}
