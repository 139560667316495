import {
  CircularProgress as Progress,
  CircularProgressProps,
} from '@mui/material'
import { AbsolutelyCentered } from 'ui/utils'

type Props = CircularProgressProps & {
  centered?: boolean
}

export const CircularProgress = ({ centered, ...props }: Props) => {
  if (centered) {
    return (
      <AbsolutelyCentered>
        <Progress {...props} />
      </AbsolutelyCentered>
    )
  }

  return <Progress {...props} />
}
