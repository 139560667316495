export const paths = {
  // SIGN IN
  login: '/login',

  // COMPANIES
  companies: '/companies',
  companyProfile: '/companies/:companyId',
  companyDetails: '/companies/:companyId/details',
  companyCharges: '/companies/:companyId/charges',
  companyUsers: '/companies/:companyId/users',
  companyUserDetails: '/companies/:companyId/user/:userId',

  // SUBSCRIPTIONS
  subscriptions: '/subscription-plans',
  createSubscription: '/subscription-plans/create',
  subscriptionDetails: '/subscription-plans/:subscriptionId',
  editSubscription: '/subscription-plans/:subscriptionId/edit',

  // SUBSCRIPTIONS CONFIGURATION
  subscriptionsConfiguration: '/subscriptions-configuration',

  // PROMOTIONS
  promotions: '/promotions',
  createPromotion: '/promotions/create',
  editPromotion: '/promotions/:promotionId/edit',

  // EMAILs
  emails: '/emails',

  // CHARGES
  charges: '/charges',

  //Development
  iconsList: '/icons-list',
} as const
