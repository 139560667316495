import {
  createListSearchParams,
  ListOrder,
  ListPagination,
} from 'api/list-params'
import { pipe } from 'fp-ts/lib/function'
import {
  decodeJson,
  decodeJsonWithTotal,
  del,
  get,
  post,
  put,
} from 'lib/request'

import {
  SubscriptionAbstracts,
  TDefaultSubscription,
  TDefaultSubscriptionsList,
  TNullableDefaultSubscription,
  TSubscriptionDetails,
  TSubscriptionFeatures,
  TSubscriptionRow,
} from './subscriptions.codecs'

export type SubscriptionInput = {
  name: string
  description?: string
  type: 'PUBLIC' | 'PRIVATE'
  features: Array<string>
  price: number
  discount?: number
  discountDuration?: number
  periodType: string
  currency: string
  trialAvailable: boolean
  trialDuration?: number
  trialPeriodType?: string
  abstracts?: SubscriptionAbstracts
  label?: string
}

const URL = 'backoffice/subscriptions/:subscriptionId'

export const createSubscription = async (input: SubscriptionInput) => {
  return await post('backoffice/subscriptions', {
    body: input,
  })
}

export type GetSubscriptionsListInput = {
  pagination: ListPagination
  order: ListOrder
}

export const getSubscriptionsList = async ({
  pagination,
  order,
}: GetSubscriptionsListInput) => {
  const params = createListSearchParams({
    pagination,
    order,
  })

  return pipe(
    await get('backoffice/subscriptions', { query: params }),
    decodeJsonWithTotal(TSubscriptionRow, pagination),
  )
}

export const getSubscriptionFeatures = async () => {
  return pipe(
    await get('backoffice/subscriptions/features', {}),
    decodeJson(TSubscriptionFeatures),
  )
}

export type GetSubscriptionDetailsInput = {
  subscriptionId: string
}

export const getSubscriptionDetails = async ({
  subscriptionId,
}: GetSubscriptionDetailsInput) => {
  return pipe(
    await get(URL, {
      params: { subscriptionId },
    }),
    decodeJson(TSubscriptionDetails),
  )
}

export const updateSubscription = async ({
  subscriptionId,
  ...input
}: {
  subscriptionId: string
} & SubscriptionInput) => {
  return pipe(
    await put(URL, {
      params: { subscriptionId },
      body: input,
    }),
    decodeJson(TSubscriptionDetails),
  )
}

export const deleteSubscription = async (subscriptionId: string) => {
  return await del(URL, {
    params: { subscriptionId },
  })
}

export type GetCompanySubscriptionInput = {
  companyId: string
}

export const getDefaultSubscription = async () => {
  return pipe(
    await get('backoffice/subscriptions/default', {}),
    decodeJson(TNullableDefaultSubscription, { isStatus204Null: true }),
  )
}

export const getDefaultSubscriptionsList = async () => {
  return pipe(
    await get('backoffice/subscriptions/default/suggestions', {}),
    decodeJson(TDefaultSubscriptionsList),
  )
}

export const selectDefaultSubscription = async ({
  subscriptionId,
}: {
  subscriptionId: string
}) => {
  return pipe(
    await put(`backoffice/subscriptions/${subscriptionId}/default`, {}),
    decodeJson(TDefaultSubscription),
  )
}
