import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export const IconCurrencyCircle = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 24 24">
    <path
      d="M17.4916 10.4465H23.6016C23.6754 10.4466 23.7477 10.4676 23.81 10.5072C23.8723 10.5469 23.922 10.6034 23.9533 10.6703C23.9846 10.7371 23.9962 10.8115 23.9867 10.8847C23.9773 10.9579 23.9472 11.0269 23.9 11.0836L20.845 14.7509C20.8085 14.7946 20.7629 14.8298 20.7113 14.8539C20.6598 14.878 20.6035 14.8906 20.5466 14.8906C20.4897 14.8906 20.4335 14.878 20.3819 14.8539C20.3303 14.8298 20.2847 14.7946 20.2483 14.7509L17.1933 11.0836C17.146 11.0269 17.1159 10.9579 17.1065 10.8847C17.097 10.8115 17.1087 10.7371 17.14 10.6703C17.1713 10.6034 17.221 10.5469 17.2832 10.5072C17.3455 10.4676 17.4178 10.4466 17.4916 10.4465ZM0.398587 13.5544H6.50857C6.58239 13.5543 6.65467 13.5333 6.71695 13.4937C6.77923 13.454 6.82893 13.3975 6.86024 13.3306C6.89154 13.2638 6.90315 13.1894 6.89371 13.1162C6.88427 13.043 6.85416 12.974 6.80692 12.9173L3.75193 9.25003C3.71547 9.20631 3.66985 9.17114 3.61829 9.147C3.56674 9.12286 3.51051 9.11035 3.45358 9.11035C3.39665 9.11035 3.34042 9.12286 3.28886 9.147C3.23731 9.17114 3.19169 9.20631 3.15523 9.25003L0.100235 12.9173C0.052993 12.974 0.0228879 13.043 0.0134455 13.1162C0.00400317 13.1894 0.0156143 13.2638 0.0469194 13.3306C0.0782245 13.3975 0.127928 13.454 0.190209 13.4937C0.25249 13.5333 0.32477 13.5543 0.398587 13.5544Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.9997 4.23066C9.588 4.23066 7.43117 5.32927 6.00624 7.05567C5.94261 7.13814 5.86305 7.20697 5.77228 7.25808C5.68152 7.30919 5.5814 7.34152 5.47789 7.35316C5.37438 7.36479 5.26958 7.3555 5.16974 7.32582C5.06989 7.29615 4.97703 7.2467 4.89668 7.18042C4.81632 7.11413 4.75012 7.03237 4.702 6.93999C4.65388 6.8476 4.62483 6.74649 4.61657 6.64265C4.60831 6.53882 4.62102 6.43438 4.65394 6.33556C4.68685 6.23673 4.7393 6.14553 4.80817 6.06738C5.94593 4.68988 7.4554 3.66816 9.15705 3.12374C10.8587 2.57931 12.6809 2.5351 14.407 2.99637C16.133 3.45763 17.6903 4.40495 18.8935 5.72565C20.0967 7.04636 20.8953 8.68487 21.1942 10.4463H19.6138C19.2551 8.69164 18.3014 7.11471 16.914 5.98214C15.5266 4.84958 13.7906 4.23088 11.9997 4.23066ZM4.3855 13.5541C4.67968 14.9909 5.37434 16.3152 6.38924 17.3739C7.40414 18.4326 8.69786 19.1825 10.1209 19.5371C11.544 19.8917 13.0384 19.8365 14.4314 19.3777C15.8244 18.919 17.0592 18.0755 17.9931 16.9448C18.0567 16.8623 18.1363 16.7935 18.2271 16.7424C18.3178 16.6912 18.418 16.6589 18.5215 16.6473C18.625 16.6356 18.7298 16.6449 18.8296 16.6746C18.9295 16.7043 19.0223 16.7537 19.1027 16.82C19.183 16.8863 19.2492 16.9681 19.2974 17.0604C19.3455 17.1528 19.3745 17.2539 19.3828 17.3578C19.391 17.4616 19.3783 17.5661 19.3454 17.6649C19.3125 17.7637 19.2601 17.8549 19.1912 17.9331C18.0534 19.3106 16.544 20.3323 14.8423 20.8767C13.1407 21.4211 11.3184 21.4653 9.59239 21.0041C7.86635 20.5428 6.3091 19.5955 5.10588 18.2748C3.90266 16.9541 3.1041 15.3156 2.80518 13.5541H4.3855Z"
      fill="currentColor"
    />
    <path
      d="M14.9069 13.5783C14.9069 11.9358 13.7493 11.4648 12.497 11.1997L11.8332 11.0653C10.8517 10.8723 10.776 10.5876 10.776 10.2065C10.776 9.71192 11.2216 9.41636 11.9682 9.41636C12.8523 9.41636 13.0788 9.86047 13.1728 10.1941L13.1818 10.2218C13.293 10.5127 13.5513 10.6862 13.8737 10.6862C13.9572 10.6862 14.0323 10.6735 14.0884 10.6621C14.4344 10.5933 14.6754 10.3178 14.6754 9.99164C14.6754 9.91073 14.6608 9.83052 14.6313 9.75254C14.454 9.18242 13.9559 8.28507 12.5755 8.08023V7.10249C12.5755 6.2881 11.2999 6.2881 11.2999 7.10249V8.08608C9.81716 8.31812 9.27114 9.32684 9.27114 10.2061C9.27114 11.806 10.3852 12.252 11.4735 12.4764L12.1914 12.629C13.2139 12.8369 13.3942 13.1035 13.3942 13.5866C13.3942 14.1768 12.8951 14.5287 12.0586 14.5287C10.9725 14.5287 10.7512 14.0396 10.6031 13.4907C10.5165 13.1869 10.242 12.9911 9.9034 12.9911C9.82955 12.9911 9.77033 13.0009 9.6989 13.0132L9.67738 13.0173C9.32725 13.1004 9.09229 13.3762 9.09229 13.7031C9.09229 13.7652 9.1033 13.8181 9.11225 13.8608L9.12327 13.9097C9.28078 14.4421 9.62849 15.6049 11.3628 15.8643V16.8876C11.3628 17.2951 11.6833 17.5084 12.0004 17.5084C12.3175 17.5084 12.6383 17.2951 12.6383 16.8876V15.8822C14.0154 15.7028 14.9083 14.823 14.9083 13.5785"
      fill="currentColor"
    />
  </SvgIcon>
)
