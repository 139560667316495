import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export const IconCalendarDayOutline = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 18 18">
    <path
      d="M3.9375 0C4.08668 0 4.22976 0.0592632 4.33525 0.164752C4.44074 0.270242 4.5 0.413316 4.5 0.5625V1.125H13.5V0.5625C13.5 0.413316 13.5593 0.270242 13.6648 0.164752C13.7702 0.0592632 13.9133 0 14.0625 0C14.2117 0 14.3548 0.0592632 14.4602 0.164752C14.5657 0.270242 14.625 0.413316 14.625 0.5625V1.125H15.75C16.3467 1.125 16.919 1.36205 17.341 1.78401C17.7629 2.20597 18 2.77826 18 3.375V15.75C18 16.3467 17.7629 16.919 17.341 17.341C16.919 17.7629 16.3467 18 15.75 18H2.25C1.65326 18 1.08097 17.7629 0.65901 17.341C0.237053 16.919 0 16.3467 0 15.75V3.375C0 2.77826 0.237053 2.20597 0.65901 1.78401C1.08097 1.36205 1.65326 1.125 2.25 1.125H3.375V0.5625C3.375 0.413316 3.43426 0.270242 3.53975 0.164752C3.64524 0.0592632 3.78832 0 3.9375 0V0ZM2.25 2.25C1.95163 2.25 1.66548 2.36853 1.4545 2.5795C1.24353 2.79048 1.125 3.07663 1.125 3.375V4.5H16.875V3.375C16.875 3.07663 16.7565 2.79048 16.5455 2.5795C16.3345 2.36853 16.0484 2.25 15.75 2.25H2.25ZM16.875 5.625H1.125V15.75C1.125 16.0484 1.24353 16.3345 1.4545 16.5455C1.66548 16.7565 1.95163 16.875 2.25 16.875H15.75C16.0484 16.875 16.3345 16.7565 16.5455 16.5455C16.7565 16.3345 16.875 16.0484 16.875 15.75V5.625Z"
      fill="currentColor"
    />
    <path
      d="M12.375 8.4375C12.375 8.28832 12.4343 8.14524 12.5398 8.03975C12.6452 7.93426 12.7883 7.875 12.9375 7.875H14.0625C14.2117 7.875 14.3548 7.93426 14.4602 8.03975C14.5657 8.14524 14.625 8.28832 14.625 8.4375V9.5625C14.625 9.71168 14.5657 9.85476 14.4602 9.96025C14.3548 10.0657 14.2117 10.125 14.0625 10.125H12.9375C12.7883 10.125 12.6452 10.0657 12.5398 9.96025C12.4343 9.85476 12.375 9.71168 12.375 9.5625V8.4375Z"
      fill="currentColor"
    />
  </SvgIcon>
)
