import { Box } from '@mui/material'
import { AppSidebar } from 'components/global'

type Props = Readonly<{
  children: React.ReactNode
}>

export const AuthLayout = ({ children }: Props) => {
  return (
    <Box height="100%" display="flex">
      <AppSidebar />
      <Box
        component="main"
        flex="auto"
        minWidth={0}
        display="flex"
        flexDirection="column"
      >
        {children}
      </Box>
    </Box>
  )
}
