import { TCompanySubscription, TPaymentCurrency } from 'api/subscriptions'
import * as t from 'io-ts'
import { DateFromISOString } from 'io-ts-types'

import { TUser } from './users/users.codecs'

const TCompanyStatus = t.union([
  t.literal('ACTIVE'),
  t.literal('INACTIVE'),
  t.literal('PENDING'),
  t.literal('FROZEN'),
])

export type CompanyStatus = t.TypeOf<typeof TCompanyStatus>

export const TCompany = t.intersection([
  t.strict({
    companyId: t.string,
    name: t.string,
    activeUsersCount: t.number,
    createdAt: DateFromISOString,
    status: TCompanyStatus,
  }),
  t.partial({
    lastActivity: DateFromISOString,
    subscriptionType: t.union([t.literal('REGULAR'), t.literal('CUSTOM')]),
    promotion: t.strict({
      promotionId: t.string,
      name: t.string,
    }),
  }),
])

export type Company = t.TypeOf<typeof TCompany>

const TOwner = t.intersection([
  t.strict({
    fullName: t.string,
    email: t.string,
  }),
  t.partial({
    avatar: t.string,
  }),
])

export const TDefaultPaymentDetails = t.strict({
  brand: t.string,
  expiryMonth: t.string,
  expiryYear: t.string,
  lastFour: t.string,
  type: t.string,
})

export type DefaultPaymentDetails = t.TypeOf<typeof TDefaultPaymentDetails>

export const TCompanyDetails = t.intersection([
  t.strict({
    companyId: t.string,
    name: t.string,
    owner: TOwner,
    activeUsersCount: t.number,
    invitedUsersCount: t.number,
    createdAt: DateFromISOString,
    createdContactsCount: t.number,
    createdJobRequestsCount: t.number,
    createdJobsCount: t.number,
  }),
  t.partial({
    industry: t.strict({
      code: t.string,
      name: t.string,
    }),
    teamMembers: t.strict({
      code: t.string,
      name: t.string,
    }),
    country: t.strict({
      code: t.string,
      name: t.string,
    }),
    subscriptionType: t.union([t.literal('REGULAR'), t.literal('CUSTOM')]),
    promotion: t.strict({
      promotionId: t.string,
      name: t.string,
    }),
    logoUri: t.string,
    lastActivity: DateFromISOString,
  }),
])

export type CompanyDetails = t.TypeOf<typeof TCompanyDetails>

export const TIntegrationProvider = t.literal('GOOGLE')

export type IntegrationProvider = t.TypeOf<typeof TIntegrationProvider>

export const TIntegrationType = t.literal('EMAIL')

export type IntegrationType = t.TypeOf<typeof TIntegrationType>

export const TIntegration = t.strict({
  email: t.string,
  type: TIntegrationType,
  provider: TIntegrationProvider,
  active: t.boolean,
})

export const TIntegrations = t.array(TIntegration)

export type Integrations = t.TypeOf<typeof TIntegrations>

export const TCompanyFullDetails = t.intersection([
  t.strict({
    details: TCompanyDetails,
    integrations: TIntegrations,
  }),
  t.partial({
    subscription: TCompanySubscription,
    defaultPaymentDetails: TDefaultPaymentDetails,
  }),
])

export const TCompanyUser = t.intersection([
  TUser,
  t.partial({
    lastActivity: DateFromISOString,
  }),
])

export const TCompanyCharge = t.intersection([
  t.strict({
    subscriptionChargeId: t.string,
    subscriptionName: t.string,
    companyId: t.string,
    companyName: t.string,
    price: t.number,
    currency: TPaymentCurrency,
    seats: t.number,
    status: t.union([
      t.literal('PAID'),
      t.literal('FAILED'),
      t.literal('RETRY'),
    ]),
    billingPaid: t.boolean,
    createdAt: DateFromISOString,
    chargedAttempts: t.number,
  }),
  t.partial({
    subscriptionId: t.string,
    paymentMethodDigits: t.string,
    paymentMethodBrands: t.string,
  }),
])

export const TChargeAttempt = t.intersection([
  t.strict({
    failedChargeAttemptId: t.string,
    reason: t.string,
    createdAt: DateFromISOString,
  }),
  t.partial({
    message: t.string,
    paymentMethodDigits: t.string,
    paymentMethodBrand: t.string,
  }),
])
